<template>
    <div>
        <div class="bg-bg p-4 mb-2">
            <p class="text-xs font-semibold">Thông báo qua</p>
            <el-select
                class="mt-2 mb-2"
                v-model="send_type"
                placeholder="Select"
            >
                <el-option
                    v-for="item in send_type_options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                >
                </el-option>
            </el-select>
            <div v-if="send_type === 'email'">
                <p class="text-xs font-semibold">Danh sách email</p>
                <el-input
                    type="textarea"
                    class="w-[340px] mt-2 mb-2"
                    :rows="2"
                    placeholder="abcd@gmail.com, xyz@gmail.com, ..."
                    v-model="textarea"
                >
                </el-input>
            </div>
            <div v-if="send_type === 'telegram'">
                <p class="text-xs font-semibold">Bot Token</p>
                <el-input
                    class="w-[540px] mt-2 mb-2"
                    :rows="2"
                    placeholder="Ví dụ : 5468618769:AAE2VYvtfI-sdZL9X-ePj0uLasRFoakeSt1w4"
                    v-model="textarea"
                >
                </el-input>
                <p class="text-xs font-semibold">Chat Id</p>
                <el-input
                    class="w-[340px] mt-2 mb-2"
                    :rows="2"
                    placeholder="Ví dụ : -615216367"
                    v-model="textarea"
                >
                </el-input>
            </div>
            <div v-if="send_type === 'lark'">
                <p class="text-xs font-semibold">Bot Id</p>
                <el-input
                    class="w-[540px] mt-2 mb-2"
                    :rows="2"
                    placeholder="Ví dụ : 24b231393-78f7-47dc-900c-bdsdsadaf163"
                    v-model="textarea"
                >
                </el-input>
            </div>
        </div>
        <div class="bg-bg p-4">
            <p class="text-xs font-semibold">
                {{ $t('page.campaign.notification_schedule') }}
            </p>
            <el-radio-group
                v-model="notification_inline.email_setting.notification_period"
                class="block mt-1"
            >
                <el-radio label="EVERY_TIME">
                    {{ $t('page.campaign.notify_me_every_time') }}
                </el-radio>
            </el-radio-group>
            <el-radio-group
                v-model="notification_inline.email_setting.notification_period"
                class="block mt-3"
            >
                <el-radio label="TIME_SCHEDULE">
                    {{ $t('page.campaign.send_notification_together') }}
                </el-radio>
            </el-radio-group>
            <div
                v-if="
                    notification_inline.email_setting.notification_period ===
                    'TIME_SCHEDULE'
                "
                class="mt-2"
            >
                <el-select
                    v-model="notification_inline.email_setting.email_exec_time"
                    class="w-[240px]"
                    size="small"
                    multiple
                    :multiple-limit="3"
                >
                    <el-option
                        v-for="item in specified_time_options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                    >
                    </el-option>
                </el-select>
            </div>

            <div class="flex items-center mt-6 space-x-2">
                <el-switch
                    v-model="
                        notification_inline.email_setting.no_result_notification
                    "
                >
                </el-switch>
                <p class="text-sm">
                    {{ $t('page.campaign.also_notify_me') }}
                </p>
            </div>
            <p class="text-desc-text text-xs">
                {{ $t('page.campaign.get_notification_every_time') }}
            </p>
        </div>
    </div>
</template>

<script>
export default {
    props: ['notification'],

    data() {
        return {
            specified_time_options: [],
            send_type_options: [
                {
                    value: 'email',
                    label: 'Email'
                },
                {
                    value: 'telegram',
                    label: 'Telegram'
                },
                {
                    value: 'lark',
                    label: 'Lark'
                }
            ],
            send_type: 'email'
        }
    },

    computed: {
        notification_inline: {
            get: function () {
                return this.notification
            },
            set: function (val) {
                this.$emit('update:notification', val)
            }
        }
    },

    watch: {
        'notification_inline.email_setting.notification_period'() {
            if (
                this.notification_inline.email_setting.notification_period ===
                    'TIME_SCHEDULE' &&
                this.specified_time_options.length
            ) {
                this.notification_inline.email_setting.email_exec_time = [
                    this.specified_time_options[0].value
                ]
            } else {
                this.notification_inline.email_setting.email_exec_time = []
            }
        }
    },

    mounted() {
        this.renderSpecifiedTimeOptions()
    },

    methods: {
        renderSpecifiedTimeOptions() {
            const temp = []

            for (let index = 0; index < 24; index++) {
                let hour = `0${index}`
                if (index >= 10) {
                    hour = index
                }

                let value = `${hour}:15`
                temp.push({
                    label: value,
                    value
                })

                value = `${hour}:45`
                temp.push({
                    label: value,
                    value
                })
            }

            this.specified_time_options = temp
        }
    }
}
</script>
