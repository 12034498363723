<template>
    <div>
        <section class="component-box mb-2">
            <div class="flex items-center">
                <label-outside-input-select
                    :label="$t('common.business_center')"
                >
                    <el-select
                        :value="m__selected_business_center"
                        value-key="bc_id"
                        size="small"
                        filterable
                        :placeholder="
                            $t('input.placeholder.select_business_center')
                        "
                        class="w-[320px]"
                        @change="m__handleChangeSelectedBCNotRefreshPage"
                    >
                        <el-option-group :label="$t('common.business_center')">
                            <el-option
                                v-for="item in m__business_centers"
                                :key="item.bc_id"
                                :label="item.name"
                                :value="item"
                            >
                                <div class="flex items-center py-1 space-x-2">
                                    <div class="flex flex-col">
                                        <span
                                            class="leading-5 text-xs font-semibold"
                                        >
                                            {{ item.name }}
                                        </span>
                                        <span class="component-text-desc">
                                            #{{ item.bc_id }}
                                        </span>
                                    </div>
                                </div>
                            </el-option>
                        </el-option-group>
                    </el-select>
                </label-outside-input-select>

                <div class="!ml-auto"></div>

                <!-- <el-button
                    :disabled="!current_package"
                    size="small"
                    icon="el-icon-plus"
                    type="primary"
                    @click="createBC"
                    >{{ $t('button.create') }}
                </el-button> -->
            </div>
        </section>
        <section v-loading="m__loading" class="component-box">
            <div class="flex items-center justify-between mb-4 space-x-2">
                <div>
                    <p class="font-semibold">
                        {{ $t('common.tiktok_identity') }}
                    </p>
                </div>

                <div>
                    <!-- <el-button size="small" @click="openTSPOnNewTab">
                        {{ $t('page.shop.register_new_tts') }}
                    </el-button> -->

                    <!-- <el-button
                    :disabled="!current_package || !m__selected_tiktok_business"
                    type="primary"
                    size="small"
                    icon="el-icon-s-shop"
                    @click="show_dialog.add_shop = true"
                >
                    {{ $t('page.shop.manage_tiktok_shop') }}
                </el-button> -->
                </div>
            </div>

            <!-- <el-alert
            :title="$t('page.shop.info')"
            type="success"
            class="mb-2"
            :closable="false"
        >
        </el-alert> -->

            <!-- <p class="text-sm text-red mb-4">
            <i class="el-icon-info text-desc-text"></i>

            {{ $t('page.shop.info') }}
        </p> -->

            <el-table :data="identity_list">
                <el-table-column label="#" type="index" width="60" />

                <el-table-column
                    :label="$t('page.tiktok_identity.tiktok_id')"
                    width="240"
                >
                    <template slot-scope="scope">
                        {{ scope.row.asset_id }}
                    </template>
                </el-table-column>

                <el-table-column
                    label="$t('page.tiktok_identity.tiktok_id')"
                    width="400"
                >
                    <template slot-scope="scope">
                        {{ scope.row.asset_name }}
                    </template>
                </el-table-column>

                <el-table-column :label="'Owner BC'">
                    <template slot-scope="scope">
                        {{ scope.row.owner_bc_name }}
                    </template>
                </el-table-column>
            </el-table>

            <div class="flex items-center justify-between mt-2">
                <el-pagination
                    :current-page.sync="m__page_info.page"
                    :page-size="m__page_info.page_size"
                    layout="prev, pager, next, total"
                    :total="m__page_info.total_number"
                />
                <div class="flex items-center">
                    <el-switch
                        v-model="switch_auto_refresh"
                        @change="handleToggleAutoRefresh"
                    ></el-switch>
                    <p class="text-xs mx-1">
                        {{ $t('page.campaign.auto_refresh') }}
                    </p>
                    <el-tooltip
                        effect="dark"
                        :content="$t('page.campaign.desc_auto_refresh')"
                        placement="top"
                    >
                        <i class="el-icon-info text-desc-text"></i>
                    </el-tooltip>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import paginationDataMixin from '@/plugins/mixins/pagination-data'
import selectedBusinessCenterMixin from '@/plugins/mixins/selected-business-center'
import selectedTiktokBusinessMixin from '@/plugins/mixins/selected-tiktok-business'
import { getAssets } from '@/services/atosa-tiktok-ads/asset'
import {
    commitRequestAnIdentity,
    deleteRequestIdentity,
    getRequestIdentity,
    requestAnIdentity
} from '@/services/atosa-tiktok-ads/identity'

export default {
    mixins: [
        selectedTiktokBusinessMixin,
        selectedBusinessCenterMixin,
        paginationDataMixin
    ],

    data() {
        return {
            polling: null,
            switch_auto_refresh: true,
            show_dialog: {
                add_tiktok_account: false,
                get_qr_code: false
            },
            loading: {
                add_tiktok_account: false,
                get_qr_code: false
            },
            form_add_tiktok_account: {
                tiktok_username: ''
            },
            request_list: [],
            identity_list: []
        }
    },

    computed: {
        current_package() {
            return this.$store.getters.currentPackage.s__current_package
        }
    },

    watch: {
        m__selected_tiktok_business: function () {
            this.getRequestIdentity()
        },
        m__selected_business_center: {
            handler(newValue, oldValue) {
                console.log('Value changed:', { newValue, oldValue })
                this.fetchTiktokIdentity() // Hàm được gọi khi bất kỳ thay đổi nào xảy ra
            },
            deep: true
        }
    },

    mounted() {
        this.getRequestIdentity()

        this.handleToggleAutoRefresh()
    },

    beforeDestroy() {
        clearInterval(this.polling)
    },

    methods: {
        handleToggleAutoRefresh() {
            if (this.switch_auto_refresh) {
                this.polling = setInterval(() => {
                    this.getRequestIdentity()
                }, 10e3)
            } else {
                clearInterval(this.polling)
            }
        },

        async fetchTiktokIdentity() {
            console.log(
                'fetchTiktokShop dasad',
                this.m__selected_business_center
            )

            if (
                !this.m__selected_tiktok_business.id ||
                !this.m__selected_business_center ||
                !this.m__selected_business_center.bc_id
            ) {
                return
            }

            this.m__loading = true
            try {
                const response = await getAssets(
                    this.m__selected_tiktok_business.id,
                    {
                        bc_id: this.m__selected_business_center.bc_id,
                        asset_type: 'TT_ACCOUNT'
                    }
                )
                this.identity_list = response.data.list
            } catch (e) {
                console.error(e)
            } finally {
                this.m__loading = false
            }
        },

        async copyQRCode() {
            await this.p__CopyText(this.getQrCodeURL())
        },

        getQrCodeURL() {
            return `${process.env.VUE_APP_ATOSA_TIKTOK_ADS_API.replace(
                '/api/v1',
                ''
            )}/qrcode/qrcode.png`
        },

        async getRequestIdentity() {
            if (!this.m__selected_tiktok_business) {
                return
            }

            this.m__loading = true

            try {
                const response = await getRequestIdentity(
                    this.m__selected_tiktok_business.id
                )
                this.request_list = response.data
            } catch (error) {
                console.error(error)
            }

            this.m__loading = false
        },

        async deleteRequestIdentity(tiktok_username) {
            if (!this.m__selected_tiktok_business) {
                return
            }

            try {
                await this.$confirm(
                    this.$t('common.delete_data_confirm'),
                    this.$t('common.warning'),
                    {
                        confirmButtonText: this.$t('button.confirm'),
                        cancelButtonText: this.$t('button.close'),
                        type: 'warning'
                    }
                )
            } catch (e) {
                return
            }

            try {
                await deleteRequestIdentity(
                    this.m__selected_tiktok_business.id,
                    tiktok_username
                )
                await this.getRequestIdentity()
                this.p__showNotify(
                    'success',
                    this.$t('common.delete_data_success')
                )
            } catch (e) {
                console.error(e)
            }
        },

        async requestAnIdentity() {
            if (
                !this.m__selected_tiktok_business ||
                !this.form_add_tiktok_account.tiktok_username
            ) {
                return
            }

            this.loading.add_tiktok_account = true
            try {
                await requestAnIdentity(
                    this.m__selected_tiktok_business.id,
                    this.form_add_tiktok_account
                )
                await this.getRequestIdentity()
                this.p__showNotify(
                    'success',
                    this.$t('common.request_sent_success')
                )
                this.show_dialog.add_tiktok_account = false
                this.show_dialog.get_qr_code = true
            } catch (e) {
                console.error(e)
            } finally {
                this.loading.add_tiktok_account = false
            }
        },

        async commitRequestAnIdentity(data) {
            if (!this.m__selected_tiktok_business) {
                return
            }

            try {
                await this.$confirm(
                    this.$t('page.tiktok_identity.confirm_linked_message'),
                    this.$t('common.warning'),
                    {
                        confirmButtonText: this.$t('button.confirm'),
                        cancelButtonText: this.$t('button.close'),
                        type: 'warning'
                    }
                )
            } catch (e) {
                return
            }

            try {
                await commitRequestAnIdentity(
                    this.m__selected_tiktok_business.id,
                    data.tiktok_username
                )
                await this.getRequestIdentity()
                this.p__showNotify(
                    'success',
                    this.$t('common.update_data_success')
                )
            } catch (e) {
                console.error(e)
            }
        }
    }
}
</script>
