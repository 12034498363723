import request from './_config'
const path = `auth_tiktok_post`

export function getInfoTiktokPost(tiktok_account_id, data) {
    return request({
        url: `${path}/info/${tiktok_account_id}`,
        method: 'post',
        data: {
            advertiser_id: data.advertiser_id,
            auth_code: data.auth_code
        }
    })
}

export function ApplyTiktokPost(tiktok_account_id, data) {
    return request({
        url: `${path}/auth/${tiktok_account_id}`,
        method: 'post',
        data: {
            advertiser_id: data.advertiser_id,
            auth_code: data.auth_code
        }
    })
}

export function getTiktokPostList(tiktok_account_id, data) {
    return request({
        url: `${path}/info/${tiktok_account_id}`,
        method: 'get',
        data: {
            advertiser_id: data.advertiser_id
        }
    })
}
