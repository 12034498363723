var render = function render(){var _vm=this,_c=_vm._self._c;return _c('custom-drawer',{attrs:{"size":"800px","wrapper_closable":false,"confirm_to_close":false,"visible":_vm.visible_in_line},on:{"update:visible":function($event){_vm.visible_in_line=$event}}},[_c('template',{slot:"title"},[_c('div',{staticClass:"flex items-center h-full pl-4 pr-8 space-x-4"},[_c('p',{staticClass:"font-semibold"},[_vm._v(" "+_vm._s(_vm.$t('page.campaign.auth_tiktok_post'))+" ")]),_c('div',{staticClass:"!ml-auto"}),_c('el-button',{attrs:{"icon":"el-icon-check","type":"primary","size":"small","disabled":_vm.multiple_selection.length === 0},on:{"click":_vm.handleChooseVideoForAd}},[_vm._v(" "+_vm._s(_vm.$t('button.confirm'))+" ")])],1)]),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.m__loading),expression:"m__loading"}],staticClass:"h-full pt-4 pb-8 pl-[84px] pr-12 overflow-auto bg-white"},[_c('div',{staticClass:"flex space-x-2"},[_c('label-outside-input-select',{attrs:{"label":_vm.$t('common.auth_code')}},[_c('el-input',{staticClass:"w-[440px]",attrs:{"type":"text","size":"small","clearable":true,"placeholder":_vm.$t('input.placeholder.please_input')},model:{value:(_vm.auth_code),callback:function ($$v) {_vm.auth_code=$$v},expression:"auth_code"}},[_c('el-button',{attrs:{"slot":"append","icon":"el-icon-search"},on:{"click":_vm.findTiktokPostByCode},slot:"append"})],1)],1)],1),(_vm.data_list.length)?_c('div',{staticClass:"flex flex-wrap gap-4 mt-4"},_vm._l((_vm.data_list),function(item){return _c('div',{key:item.item_id},[_c('div',{staticClass:"w-[180px] relative h-[180px] group",class:[
                        _vm.handleHasChoosen(item.item_id)
                            ? ''
                            : 'hover:opacity-80'
                    ]},[_c('div',{staticClass:"w-auto h-full rounded-lg cursor-pointer relative border-[1px]",class:[
                            _vm.handleHasChoosen(item.item_id)
                                ? 'border-brand-atosa'
                                : 'border-transparent bg-bg'
                        ],on:{"click":function($event){return _vm.handleChooseVideo(item)}}},[(_vm.handleHasChoosen(item.item_id))?_c('div',{staticClass:"bg-brand-atosa opacity-20 absolute top-0 left-0 z-20 w-full h-full"}):_vm._e(),_c('img',{staticClass:"object-contain w-full h-full",attrs:{"src":item.video_info.poster_url,"alt":""}}),_c('div',{staticClass:"top-1 right-2 absolute"},[_c('el-checkbox',{attrs:{"value":_vm.handleHasChoosen(item.item_id)},on:{"input":function($event){return _vm.handleChooseVideo(item)}}})],1),_c('div',{staticClass:"absolute bottom-2 right-2 bg-black p-[2px] rounded-xl"},[_c('p',{staticClass:"text-xxs text-white"},[_vm._v(" "+_vm._s(_vm.p__renderDurationToMinute( item.video_info.duration ))+" ")])])]),_c('div',{staticClass:"top-1/2 left-1/2 w-7 h-7 group-hover:w-8 group-hover:h-8 util-flex-center absolute z-40 -translate-x-1/2 -translate-y-1/2 bg-black rounded-full cursor-pointer",on:{"click":function($event){return _vm.handleChooseVideoToShow(item.video_info.url)}}},[_c('i',{staticClass:"el-icon-caret-right text-xl text-white"})])]),_c('p',{staticClass:"text-xs truncate w-[180px]"},[_vm._v(" "+_vm._s(item.text)+" ")])])}),0):_c('div',{staticClass:"util-flex-center mt-14 flex-col"},[_c('img',{staticClass:"w-[140px] h-[140px]",attrs:{"src":require('@/assets/images/no_data.png')}}),_c('p',{staticClass:"text-desc-text mt-4 text-sm"},[_vm._v(" "+_vm._s(_vm.$t('common.no_data'))+" ")])])]),_c('InfoVideoDialog',{attrs:{"visible":_vm.show_info_video_dialog,"preview_url":_vm.preview_url},on:{"update:visible":function($event){_vm.show_info_video_dialog=$event}}})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }