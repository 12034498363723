var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"component-box"},[_c('p',{staticClass:"mb-8 font-semibold"},[_vm._v(" "+_vm._s(_vm.$t('page.campaign.ad_details'))+" ")]),_c('div',{staticClass:"flex flex-col space-y-6"},[(_vm.objective_type === 'PRODUCT_SALES')?_c('div',[(
                    _vm.selected_item_in_line.extension.shopping_ads_type ===
                    'PRODUCT_SHOPPING_ADS'
                )?_c('p',{staticClass:"text-xs"},[_vm._v(" "+_vm._s(_vm.$t('page.campaign.choose_product_from'))+" "),_c('b',[_vm._v(_vm._s(_vm.selected_item_in_line.extension.store_name))])]):_c('div',{staticClass:"flex items-center text-xs"},[(
                        _vm.selected_item_in_line.extension
                            .shopping_ads_type !== 'LIVE'
                    )?_c('p',[_vm._v(" "+_vm._s(_vm.$t('page.campaign.product_post_delivered'))+" ")]):_vm._e(),(
                        _vm.selected_item_in_line.extension
                            .shopping_ads_type === 'LIVE'
                    )?_c('p',[_vm._v(" "+_vm._s(_vm.$t('page.campaign.post_delivered'))+" ")]):_vm._e(),_c('div',{staticClass:"bg-bg rounded-xl flex items-center pr-2 ml-2 space-x-1"},[_c('el-avatar',{attrs:{"size":21,"src":_vm.selected_item_in_line.extension.identity
                                .image_uri}}),_c('p',[_vm._v(" "+_vm._s(_vm.selected_item_in_line.extension.identity .display_name)+" ")])],1)])]):_vm._e(),(
                ['PRODUCT_SALES'].every((item) => item !== _vm.objective_type)
            )?_c('div',{staticClass:"border-border border-b-[1px]"},[_c('p',{staticClass:"text-desc-text mb-4 text-xs font-semibold"},[_vm._v(" "+_vm._s(_vm.$t('page.campaign.ad_format'))+" ")]),_vm._l((_vm.ad_format_options),function(i,idx){return _c('div',{key:idx,staticClass:"mt-4 rounded-lg py-4 px-8 flex space-x-4 cursor-pointer border-[1px] box-border",class:[
                    _vm.selected_item.ad_format === i.value
                        ? 'border-brand-atosa bg-white'
                        : 'border-transparent bg-bg hover:bg-border'
                ],on:{"click":function($event){_vm.selected_item.ad_format = i.value}}},[_c('div',{staticClass:"min-w-10"},[_c('font-awesome-icon',{attrs:{"size":"lg","icon":i.icon}})],1),_c('div',[_c('p',{staticClass:"mb-1 text-base font-semibold"},[_vm._v(" "+_vm._s(i.title)+" ")]),_c('p',{staticClass:"component-text-desc"},[_vm._v(" "+_vm._s(i.description)+" ")])])])})],2):_vm._e(),(
                _vm.objective_type === 'PRODUCT_SALES' &&
                _vm.selected_item_in_line.extension.shopping_ads_type !== 'LIVE'
            )?_c('div',[_c('div',{staticClass:"flex items-center"},[_c('p',{staticClass:"text-xs font-semibold"},[_vm._v(" "+_vm._s(_vm.$t('common.products'))+" ")]),_c('el-button',{staticClass:"!ml-auto text-xs",attrs:{"type":"text","disabled":!_vm.selected_item_in_line.extension.selected_products
                            .length},on:{"click":_vm.handleRemoveAllProduct}},[_vm._v(" "+_vm._s(_vm.$t('common.delete_all')))])],1),_c('el-alert',{staticClass:"mb-2",attrs:{"type":"info","title":_vm.$t('page.campaign.desc_product')}}),(
                    _vm.selected_item_in_line.extension.shopping_ads_type ===
                    'PRODUCT_SHOPPING_ADS'
                )?_c('el-select',{staticClass:"w-full mb-2",attrs:{"size":"small","placeholder":_vm.$t('input.placeholder.please_select'),"filterable":""},model:{value:(_vm.selected_item_in_line.product_specific_type),callback:function ($$v) {_vm.$set(_vm.selected_item_in_line, "product_specific_type", $$v)},expression:"selected_item_in_line.product_specific_type"}},_vm._l((_vm.product_specific_type_options),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.title,"value":item.value}})}),1):_vm._e(),(_vm.selected_item_in_line.product_specific_type !== 'ALL')?_c('div',[(
                        _vm.selected_item_in_line.extension.selected_products
                            .length
                    )?_c('div',{staticClass:"overflow-auto pr-2 max-h-[280px] flex flex-col space-y-2 mb-4"},[_c('draggable',{staticClass:"flex flex-col flex-wrap gap-1",attrs:{"options":{ handle: '.drag-handle' }},model:{value:(
                            _vm.selected_item_in_line.extension
                                .selected_products
                        ),callback:function ($$v) {_vm.$set(_vm.selected_item_in_line.extension
                                , "selected_products", $$v)},expression:"\n                            selected_item_in_line.extension\n                                .selected_products\n                        "}},_vm._l((_vm.selected_item_in_line.extension
                                .selected_products),function(item){return _c('div',{key:item.item_group_id,staticClass:"bg-bg drag-item drag-handle flex w-full p-3 space-x-3 rounded-lg"},[_c('p',{staticClass:"font-semibold cursor-pointer"},[_vm._v("::")]),_c('img',{staticClass:"w-[60px] h-[60px] object-contain",attrs:{"src":item.image_url}}),_c('div',[_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":item.title,"placement":"top"}},[_c('p',{staticClass:"truncate font-semibold w-[280px] text-sm"},[_vm._v(" "+_vm._s(item.title)+" ")])]),_c('p',{staticClass:"text-desc-text mt-1 text-xs"},[_vm._v(" SPU ID : "+_vm._s(item.item_group_id)+" ")]),(item.price_list)?_c('p',{staticClass:"mt-1 text-xs"},[_vm._v(" "+_vm._s(_vm.showPrice( item.price_list, item.currency ))+" ")]):_c('p',[_vm._v(" Sale: "+_vm._s(_vm.p__renderMoneyByCurrency( item.min_price, item.currency ))+" ")])],1),_c('el-button',{staticClass:"!ml-auto self-center",attrs:{"type":"text"},on:{"click":function($event){return _vm.handleRemoveProduct(item.item_group_id)}}},[_vm._v(" "+_vm._s(_vm.$t('common.delete')))])],1)}),0)],1):_vm._e(),_c('el-button',{attrs:{"icon":"el-icon-plus","size":"small"},on:{"click":function($event){_vm.is_add_product_drawer_displayed = true}}},[_vm._v(" "+_vm._s(_vm.$t('page.campaign.add_products'))+" ")])],1):_vm._e()],1):_vm._e(),(
                _vm.objective_type !== 'PRODUCT_SALES' ||
                (_vm.objective_type === 'PRODUCT_SALES' &&
                    _vm.selected_item_in_line.extension.shopping_ads_type ===
                        'LIVE' &&
                    _vm.selected_item_in_line.extension
                        .shopping_live_ad_setup === 'standard_video') ||
                (_vm.objective_type === 'PRODUCT_SALES' &&
                    _vm.selected_item_in_line.extension.shopping_ads_type ===
                        'VIDEO')
            )?_c('div',[_c('p',{staticClass:"text-desc-text mb-4 text-xs font-semibold"},[_vm._v(" "+_vm._s(_vm.$t('page.campaign.ad_creative'))+" ")]),_c('div',{staticClass:"flex flex-col space-y-6"},[_c('div',[_c('p',{staticClass:"component-label"},[_vm._v("Video")]),(_vm.selected_item.extension.video)?_c('div',{staticClass:"bg-bg flex mb-4 rounded"},[_c('div',{staticClass:"min-w-[72px] w-[72px] h-[72px] bg-bg rounded-xl overflow-hidden relative cursor-pointer",on:{"click":function($event){return _vm.handleShowInfoVideoDialog(
                                    _vm.selected_item.extension.video
                                )}}},[(
                                    _vm.selected_item.extension.video.poster_url
                                )?_c('img',{staticClass:"object-contain w-full h-full",attrs:{"src":_vm.selected_item.extension.video.poster_url,"alt":""}}):_vm._e(),(
                                    _vm.selected_item.extension.video
                                        .video_cover_url
                                )?_c('img',{staticClass:"object-contain w-full h-full",attrs:{"src":_vm.selected_item.extension.video
                                        .video_cover_url,"alt":""}}):_vm._e(),_vm._m(0)]),_c('div',{staticClass:"flex flex-col justify-center space-y-1"},[_c('div',{staticClass:"flex items-end space-x-2"},[(
                                        _vm.selected_item.extension.video
                                            .file_name
                                    )?_c('div',[_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":_vm.selected_item.extension.video
                                                .file_name,"placement":"top"}},[_c('p',{staticClass:"text-xs truncate max-w-[240px]"},[_vm._v(" "+_vm._s(_vm.selected_item.extension .video.file_name)+" ")])])],1):_vm._e(),_c('span',{staticClass:"text-desc-text text-xs"},[_vm._v(" "+_vm._s(_vm.p__renderDurationToMinute( _vm.selected_item.extension.video .duration ))+" | "+_vm._s(_vm.selected_item.extension.video.width)+" x "+_vm._s(_vm.selected_item.extension.video.height)+" ")])]),_c('div',{staticClass:"cursor-pointer"},[_c('el-dropdown',{attrs:{"trigger":"click"}},[_c('div',{staticClass:"flex items-center space-x-1"},[_c('i',{staticClass:"el-icon-edit-outline text-sm"}),_c('span',{staticClass:"text-xs"},[_vm._v(" "+_vm._s(_vm.$t('common.update'))+" ")])]),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},[_c('el-dropdown-item',{nativeOn:{"click":function($event){return _vm.handleChangeVideo.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.selected_item_in_line.video_id ? _vm.$t( 'page.campaign.tiktok_post' ) : _vm.$t( 'page.campaign.from_library' ))+" ")])],1)],1)],1)])]):_vm._e(),_c('div',[(
                                _vm.selected_item_in_line.identity_type ===
                                    'BC_AUTH_TT' ||
                                _vm.objective_type === 'PRODUCT_SALES'
                            )?_c('el-button',{attrs:{"icon":"el-icon-plus","size":"small"},on:{"click":function($event){_vm.is_from_tiktok_post_displayed = true}}},[_vm._v(" "+_vm._s(_vm.$t('page.campaign.tiktok_post'))+" ")]):_vm._e(),(
                                _vm.selected_item_in_line.identity_type !==
                                'BC_AUTH_TT'
                            )?_c('el-button',{attrs:{"icon":"el-icon-plus","size":"small"},on:{"click":function($event){_vm.is_from_library_drawer_displayed = true}}},[_vm._v(" "+_vm._s(_vm.$t('page.campaign.from_library'))+" ")]):_vm._e(),(
                                _vm.selected_item_in_line.identity_type !==
                                'BC_AUTH_TT'
                            )?_c('el-button',{attrs:{"icon":"el-icon-upload2","size":"small"},on:{"click":function($event){_vm.is_upload_video_library_drawer_displayed = true}}},[_vm._v(" "+_vm._s(_vm.$t('common.upload_file'))+" ")]):_vm._e()],1)]),_c('div',{attrs:{"id":"input_ad_text_wrapper"}},[_c('p',{staticClass:"component-label"},[_vm._v(" "+_vm._s(_vm.$t('common.text'))+" ")]),_c('el-button',{staticClass:"mb-2",attrs:{"size":"small","type":"success"},on:{"click":function($event){_vm.is_keyword_tool_drawer_displayed = true}}},[_vm._v(" Công cụ phân tích từ khóa ")]),_c('el-input',{attrs:{"id":"input_ad_text","type":"textarea","size":"small","placeholder":_vm.$t('input.placeholder.please_input'),"maxlength":"300","show-word-limit":"","rows":3},model:{value:(_vm.selected_item_in_line.ad_text),callback:function ($$v) {_vm.$set(_vm.selected_item_in_line, "ad_text", $$v)},expression:"selected_item_in_line.ad_text"}})],1),(
                        _vm.objective_type === 'PRODUCT_SALES' &&
                        _vm.selected_item_in_line.extension
                            .shopping_ads_type !== 'LIVE'
                    )?_c('div',[_c('p',{staticClass:"component-label"},[_vm._v(" "+_vm._s(_vm.$t('common.call_to_action'))+" ")]),_c('el-select',{staticClass:"w-full",attrs:{"filterable":"","size":"small"},model:{value:(_vm.selected_item_in_line.call_to_action),callback:function ($$v) {_vm.$set(_vm.selected_item_in_line, "call_to_action", $$v)},expression:"selected_item_in_line.call_to_action"}},_vm._l((_vm.p__call_to_action_options),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1)],1):_vm._e()])]):_vm._e(),(
                _vm.objective_type === 'PRODUCT_SALES' &&
                _vm.selected_item_in_line.extension.shopping_ads_type ===
                    'LIVE' &&
                _vm.selected_item_in_line.extension.shopping_live_ad_setup ===
                    'live_video'
            )?_c('div',{staticClass:"bg-bg p-6 rounded-lg"},[_c('p',{staticClass:"text-xs"},[_vm._v(" "+_vm._s(_vm.$t('page.campaign.desc_use_video_live'))+" ")])]):_vm._e(),(
                ['TRAFFIC', 'WEB_CONVERSIONS'].some(
                    (item) => item === _vm.objective_type
                )
            )?_c('div',{attrs:{"id":"input_ad_landing_page_url_wrapper"}},[_c('p',{staticClass:"component-label"},[_vm._v(" "+_vm._s(_vm.$t('page.campaign.destination_website'))+" ")]),_c('el-input',{attrs:{"id":"input_ad_landing_page_url","type":"text","size":"small","placeholder":_vm.$t('input.placeholder.please_input')},model:{value:(_vm.selected_item_in_line.landing_page_url),callback:function ($$v) {_vm.$set(_vm.selected_item_in_line, "landing_page_url", $$v)},expression:"selected_item_in_line.landing_page_url"}})],1):_vm._e()]),(
            _vm.objective_type === 'PRODUCT_SALES' &&
            _vm.selected_item.extension.shopping_ads_type !== 'LIVE'
        )?_c('AddProductsDrawer',{attrs:{"visible":_vm.is_add_product_drawer_displayed,"tiktok_account_id":_vm.tiktok_account_id,"selected_products":_vm.selected_item_in_line.extension.selected_products,"advertiser_id":_vm.advertiser_id,"belong_to_atosa":_vm.belong_to_atosa,"catalog_id":_vm.catalog_id,"currency":_vm.currency,"bc_id":_vm.bc_id,"store_id":_vm.selected_item_in_line.extension.store_id,"store_authorized_bc_id":_vm.selected_item_in_line.extension.store_authorized_bc_id,"max_products":_vm.selected_item.extension.shopping_ads_type ===
            'PRODUCT_SHOPPING_ADS'
                ? _vm.p__number_max_products_in_product_shopping_ads
                : _vm.p__number_max_products_in_video_shopping_ads,"product_source":_vm.product_source,"adgroup_info":_vm.adgroup_info},on:{"update:visible":function($event){_vm.is_add_product_drawer_displayed=$event}}}):_vm._e(),(
            _vm.selected_item_in_line.identity_type === 'BC_AUTH_TT' ||
            _vm.objective_type === 'PRODUCT_SALES'
        )?_c('FromTiktokPostDrawer',{attrs:{"visible":_vm.is_from_tiktok_post_displayed,"tiktok_account_id":_vm.tiktok_account_id,"advertiser_id":_vm.advertiser_id,"belong_to_atosa":_vm.belong_to_atosa,"identity_id":_vm.selected_item_in_line.extension.identity.identity_id,"identity_type":_vm.selected_item_in_line.extension.identity.identity_type,"identity_authorized_bc_id":_vm.selected_item_in_line.extension.identity
                .identity_authorized_bc_id},on:{"update:visible":function($event){_vm.is_from_tiktok_post_displayed=$event},"choose_video_from_tiktok_post":_vm.handleChooseVideoFromTiktokPost}}):_vm._e(),_c('FromVideoLibraryDrawer',{attrs:{"visible":_vm.is_from_library_drawer_displayed,"tiktok_account_id":_vm.tiktok_account_id,"advertiser_id":_vm.advertiser_id,"multiple":false,"belong_to_atosa":_vm.belong_to_atosa},on:{"update:visible":function($event){_vm.is_from_library_drawer_displayed=$event},"choose_video":_vm.handleChooseVideo}}),_c('UploadVideoLibraryDrawer',{attrs:{"visible":_vm.is_upload_video_library_drawer_displayed,"tiktok_account_id":_vm.tiktok_account_id,"advertiser_id":_vm.advertiser_id,"belong_to_atosa":_vm.belong_to_atosa},on:{"update:visible":function($event){_vm.is_upload_video_library_drawer_displayed=$event}}}),_c('InfoVideoDialog',{attrs:{"tiktok_account_id":_vm.tiktok_account_id,"advertiser_id":_vm.advertiser_id,"visible":_vm.show_info_video_dialog,"preview_url":_vm.preview_url,"video_id":_vm.video_id},on:{"update:visible":function($event){_vm.show_info_video_dialog=$event}}}),_c('KeywordToolDrawer',{attrs:{"visible":_vm.is_keyword_tool_drawer_displayed,"tiktok_account_id":_vm.tiktok_account_id,"addKeywordToContentFunc":_vm.setKeywordToContent},on:{"update:visible":function($event){_vm.is_keyword_tool_drawer_displayed=$event}}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"top-1/2 left-1/2 absolute -translate-x-1/2 -translate-y-1/2"},[_c('i',{staticClass:"el-icon-caret-right text-xl text-white"})])
}]

export { render, staticRenderFns }