<template>
    <custom-drawer
        v-loading="m__loading"
        size="70%"
        :confirm_to_close="false"
        :visible.sync="visible_in_line"
    >
        <template slot="title">
            <div class="flex items-center justify-between flex-1 h-full pr-5">
                <div>
                    <p class="font-semibold text-sm">
                        Danh sách file đã lưu
                    </p>
                </div>
                <!-- <el-button
                    icon="el-icon-check"
                    type="primary"
                    size="small"
                    @click="handleEditAutomatedRule"
                >
                    {{ $t('button.confirm') }}
                </el-button> -->
            </div>
        </template>
         <KeywordTool :type="'campaign'" :addKeywordToContentFunc="connect_func">
         </KeywordTool>
    </custom-drawer>
</template>

<script>
import NotifyMe from '@/views/campaign/campaign-wrapper/_components/atosa-create-automatic-rule-drawer/notify-me'
import RunSchedule from '@/views/campaign/campaign-wrapper/_components/atosa-create-automatic-rule-drawer/run-schedule'
import ConditionAndAction from '@/views/campaign/campaign-wrapper/_components/atosa-create-automatic-rule-drawer/condition-and-action'
import { updateAutomatedRule } from '@/services/atosa-tiktok-ads/auto-ads'
import KeywordFileListDrawer from '@/views/keyword-tool/_components/keyword-file-detail-drawer'
import KeywordTool from '@/views/keyword-tool'
import { getKeywordFile, deleteKeywordReport } from '@/services/atosa-tiktok-ads/keyword-tool'

export default {
    components: {
        KeywordTool
    },

    props: [
        'visible',
        'tiktok_account_id',
        'advertiser_id',
        'currency',
        'edited_item',
        'keyword_file_list',
        'addKeywordToContentFunc'
    ],

    data() {
        return {
            default_pre_condition_type_options: [
                {
                    label: this.$t('common.active'),
                    options: [
                        {
                            value: 'ALL_ACTIVE_CAMPAIGN',
                            value_dimension: 'CAMPAIGN',
                            label: this.$t('common.all_active_campaign')
                        },
                        {
                            value: 'ALL_ACTIVE_AD_GROUP',
                            value_dimension: 'ADGROUP',
                            label: this.$t('common.all_active_adgroup')
                        },
                        {
                            value: 'ALL_ACTIVE_AD',
                            value_dimension: 'AD',
                            label: this.$t('common.all_active_ad')
                        }
                    ]
                },
                {
                    label: this.$t('common.inactive'),
                    options: [
                        {
                            value: 'ALL_INACTIVE_CAMPAIGN',
                            value_dimension: 'CAMPAIGN',
                            label: this.$t('common.all_inactive_campaign')
                        },
                        {
                            value: 'ALL_INACTIVE_AD_GROUP',
                            value_dimension: 'ADGROUP',
                            label: this.$t('common.all_inactive_adgroup')
                        },
                        {
                            value: 'ALL_INACTIVE_AD',
                            value_dimension: 'AD',
                            label: this.$t('common.all_inactive_ad')
                        }
                    ]
                }
            ],
            pre_condition_type_options: [],
            file_list: [
            ],
            is_keyword_file_detail_drawer_displayed: false,
            selected_keyword_file: {}
        }
    },

    computed: {
        visible_in_line: {
            get: function () {
                return this.visible
            },
            set: function (val) {
                this.$emit('update:visible', val)
            }
        }
    },

    watch: {
        visible_in_line() {
        }
    },

    mounted() {
        this.getDataKeywordFile()

        this.pre_condition_type_options = [
            ...this.default_pre_condition_type_options
        ]
    },

    methods: {
        connect_func(content) {
            this.addKeywordToContentFunc(content)
            this.visible_in_line = false
        }
    }
}
</script>
