<template>
    <section>
        <div class="flex flex-wrap items-center gap-2">
            <el-button
                v-if="has_manager_ads"
                type="primary"
                size="small"
                icon="el-icon-plus"
                @click="handleRedirectToCreateCampaign()"
            >
                {{ $t('button.create') }}
            </el-button>

            <!-- <el-dropdown trigger="click">
                <el-button
                    size="small"
                    :disabled="!multiple_selection_inline.length"
                >
                    {{ $t('common.setting') }}
                    <i class="el-icon-arrow-down"></i>
                </el-button>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item>
                        <div
                            @click="is_config_auto_ads_dialog_displayed = true"
                        >
                            <p class="text-xs">
                                {{
                                    $t(
                                        'page.campaign.automatic_cpa_optimization'
                                    )
                                }}
                            </p>
                        </div>
                    </el-dropdown-item>
                                       <el-dropdown-item>
                                           <div
                                               @click="is_config_live_ads_dialog_displayed = true"
                                           >
                                               <p class="text-xs">
                                                   {{ $t('page.campaign.automatic_live_optimization') }}
                                               </p>
                                           </div>
                                       </el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown> -->

            <el-dropdown
                trigger="click"
                :disabled="!multiple_selection_inline.length"
            >
                <el-button size="small">
                    {{ $t('common.batch_editing') }}
                    <i class="el-icon-arrow-down"></i>
                </el-button>

                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item>
                        <div @click="m__handleMassTurnOn">
                            <span>
                                <i class="el-icon-video-play"></i>
                                {{ $t('common.turn_on') }}</span
                            >
                        </div>
                    </el-dropdown-item>
                    <el-dropdown-item>
                        <div @click="m__handleMassTurnOff">
                            <span>
                                <i class="el-icon-video-pause"></i>
                                {{ $t('common.turn_off') }}</span
                            >
                        </div>
                    </el-dropdown-item>
                    <el-dropdown-item>
                        <div @click="show_edit_multi_name_dialog = true">
                            <span>
                                {{ $t('common.batch_name_adjustment') }}</span
                            >
                        </div>
                    </el-dropdown-item>
                    <el-dropdown-item>
                        <div @click="show_edit_multi_budget_dialog = true">
                            <span>
                                {{
                                    $t('common.batch_budget_adjustments')
                                }}</span
                            >
                        </div>
                    </el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>

            <el-dropdown trigger="click">
                <el-button
                    :disabled="
                        !has_auto_ads ||
                        !current_package ||
                        !current_package.extra_data ||
                        current_package.extra_data.auto_ads === 0
                    "
                    size="small"
                >
                    {{ $t('common.automatic_rule') }}
                    <i class="el-icon-arrow-down"></i>
                </el-button>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item>
                        <div
                            @click="
                                is_create_automatic_rule_drawer_displayed = true
                            "
                        >
                            <p>
                                {{ $t('page.campaign.create_rule') }}
                            </p>
                        </div>
                    </el-dropdown-item>
                    <el-dropdown-item>
                        <div
                            @click="
                                is_manage_automatic_rule_drawer_displayed = true
                            "
                        >
                            <p>
                                {{ $t('page.campaign.manage_rule') }}
                            </p>
                        </div>
                    </el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>

            <el-button size="small" @click="show_get_cpa_drawer = true">
                {{ $t('common.cpa_suggestion') }}
            </el-button>

            <el-button
                plain
                class="ml-4"
                size="small"
                @click="show_proposal_dialog = true"
            >
                Đề xuất chân dung KH
            </el-button>

            <div class="!ml-auto"></div>

            <el-select
                v-model="m__type_statistical_detail"
                size="small"
                filterable
                class="w-[190px]"
                :placeholder="$t('input.placeholder.please_select')"
            >
                <el-option
                    :label="$t('common.detailed_analysis_none')"
                    :value="'none'"
                >
                </el-option>
                <el-option
                    :label="$t('common.detailed_analysis_day')"
                    :value="'day'"
                >
                </el-option>
                <el-option
                    :label="$t('common.detailed_analysis_week')"
                    :value="'week'"
                >
                </el-option>
                <el-option
                    :label="$t('common.detailed_analysis_month')"
                    :value="'month'"
                >
                </el-option>
            </el-select>

            <el-dropdown>
                <el-button size="small" class="!ml-0">
                    {{ $t('button.column_display') }}
                </el-button>

                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item>
                        <p
                            @click="
                                m__showColumn([
                                    {
                                        value: 'BASIC_DATA',
                                        child_values: p__basic_metrics
                                    }
                                ])
                            "
                        >
                            {{ $t('button.default_column') }}
                        </p>
                    </el-dropdown-item>
                    <el-dropdown-item>
                        <p
                            @click="
                                m__showColumn([
                                    {
                                        value: 'BASIC_DATA',
                                        child_values: p__basic_metrics
                                    },
                                    {
                                        value: 'ON_SITE_EVENT',
                                        child_values: [
                                            'onsite_shopping',
                                            'onsite_initiate_checkout_count',
                                            'onsite_on_web_detail',
                                            'total_onsite_shopping_value',
                                            'onsite_shopping_roas',
                                            'live_views',
                                            'video_play_actions'
                                        ]
                                    }
                                ])
                            "
                        >
                            {{ $t('common.shop_ads') }}
                        </p>
                    </el-dropdown-item>
                    <el-dropdown-item>
                        <p
                            @click="
                                m__showColumn([
                                    {
                                        value: 'BASIC_DATA',
                                        child_values: [
                                            'cost_per_1000_reached',
                                            'frequency',
                                            'clicks',
                                            'cpc',
                                            'ctr'
                                        ]
                                    },

                                    {
                                        value: 'ENGAGEMENT',
                                        child_values: [
                                            'engagements',
                                            'engagement_rate',
                                            'interactive_add_on_impressions',
                                            'interactive_add_on_destination_clicks'
                                        ]
                                    },
                                    {
                                        value: 'VIDEO_PLAY',
                                        child_values: [
                                            'video_watched_2s',
                                            'video_watched_6s',
                                            'video_views_p25',
                                            'video_views_p50',
                                            'video_views_p75',
                                            'video_views_p100',
                                            'average_video_play_per_user',
                                            'average_video_play'
                                        ]
                                    },
                                    {
                                        value: 'INTERACTIVE_ADD_ON',
                                        child_values: [
                                            'interactive_add_on_impressions',
                                            'interactive_add_on_destination_clicks'
                                        ]
                                    }
                                ])
                            "
                        >
                            {{ $t('common.reach') }}
                        </p>
                    </el-dropdown-item>

                    <el-dropdown-item divided>
                        <p @click="show_custom_column_dialog = true">
                            {{ $t('button.custom_column') }}
                        </p></el-dropdown-item
                    >
                </el-dropdown-menu>
            </el-dropdown>

            <div
                class="bg-bg p-2 rounded-lg cursor-pointer"
                :class="[
                    m__data_for_pagination.length ? '' : 'cursor-not-allowed'
                ]"
                @click="m__handleExportExcel"
            >
                <el-tooltip
                    class="item"
                    :content="$t(`common.export_excel`)"
                    effect="dark"
                    placement="top"
                >
                    <ExcelSvg></ExcelSvg>
                </el-tooltip>
            </div>
        </div>

        <div class="relative mt-2">
            <el-table
                ref="multipleTable"
                v-loading="m__loading"
                class="campaign-table"
                max-height="560"
                :show-summary="!name_filtering"
                :data="m__data_for_pagination"
                :summary-method="m__getSummaries"
                row-key="id"
                lazy
                :tree-props="{
                    children: 'children',
                    hasChildren: '_has_children'
                }"
                :load="m__handleLoadExpand"
                @selection-change="handleSelectionChange"
                @sort-change="m__handleSortChange"
            >
                <el-table-column
                    type="selection"
                    :reserve-selection="true"
                    width="40"
                    :fixed="true"
                >
                </el-table-column>

                <el-table-column
                    v-if="m__type_statistical_detail !== 'none'"
                    :label="''"
                    :fixed="true"
                    width="60"
                >
                </el-table-column>

                <el-table-column
                    :label="$t('table.header.on_off')"
                    :fixed="true"
                    width="60"
                >
                    <template #default="scope">
                        <el-switch
                            v-if="!scope.row.is_child"
                            size="small"
                            :disabled="
                                scope.row.campaign.secondary_status ===
                                'CAMPAIGN_STATUS_DELETE'
                            "
                            :value="
                                scope.row.campaign.operation_status === 'ENABLE'
                            "
                            @change="
                                handleChangeStatusCampaign(
                                    scope.row.campaign.campaign_id
                                )
                            "
                        >
                        </el-switch>
                    </template>
                </el-table-column>

                <el-table-column
                    min-width="360"
                    prop="campaign.campaign_name"
                    :label="$t('common.campaign')"
                    :fixed="true"
                >
                    <template #default="scope">
                        <div v-if="scope.row.is_child">
                            <p class="truncate max-w-[240px]">
                                {{ scope.row.child_name }}
                            </p>
                        </div>
                        <div
                            v-else
                            class="group hover:text-brand-atosa--light items-center"
                        >
                            <div class="group flex items-center">
                                <div
                                    v-if="
                                        scope.row.campaign.secondary_status !==
                                        'CAMPAIGN_STATUS_DELETE'
                                    "
                                    class="cursor-pointer"
                                    @click="handleSelectRow(scope.row)"
                                >
                                    <el-tooltip
                                        class="item"
                                        effect="dark"
                                        :content="
                                            scope.row.campaign.campaign_name
                                        "
                                        placement="top"
                                    >
                                        <p
                                            class="hover:underline truncate max-w-[240px]"
                                        >
                                            {{
                                                scope.row.campaign.campaign_name
                                            }}
                                        </p>
                                    </el-tooltip>
                                </div>
                                <div v-else class="cursor-pointer">
                                    <el-tooltip
                                        class="item"
                                        effect="dark"
                                        :content="
                                            scope.row.campaign.campaign_name
                                        "
                                        placement="top"
                                    >
                                        <p class="truncate max-w-[240px]">
                                            {{
                                                scope.row.campaign.campaign_name
                                            }}
                                        </p>
                                    </el-tooltip>
                                </div>

                                <el-tooltip
                                    effect="dark"
                                    :content="$t('common.edit_name')"
                                    placement="top"
                                >
                                    <div
                                        v-if="
                                            scope.row.campaign
                                                .secondary_status !==
                                                'CAMPAIGN_STATUS_DELETE' &&
                                            has_manager_ads
                                        "
                                        class="group-hover:block hidden ml-2 cursor-pointer"
                                        @click="
                                            handleSelectRowToEditName(
                                                scope.row.campaign
                                            )
                                        "
                                    >
                                        <i
                                            class="el-icon-edit text-xs font-semibold"
                                        ></i>
                                    </div>
                                </el-tooltip>
                            </div>

                            <div
                                v-if="
                                    scope.row.campaign.secondary_status !==
                                        'CAMPAIGN_STATUS_DELETE' &&
                                    !scope.row.is_child
                                "
                                class="flex items-center mt-1 space-x-3"
                            >
                                <div
                                    v-if="has_report_ads"
                                    class="hover:underline flex items-center space-x-1 cursor-pointer"
                                    @click="handleOpenViewDataDrawer(scope.row)"
                                >
                                    <font-awesome-icon
                                        icon="fa-solid fa-chart-area"
                                    ></font-awesome-icon>
                                    <p>
                                        {{ $t('button.view_data') }}
                                    </p>
                                </div>

                                <div
                                    v-if="has_manager_ads"
                                    class="hover:underline flex items-center space-x-1 cursor-pointer"
                                    @click="handleOpenEditDrawer(scope.row)"
                                >
                                    <font-awesome-icon
                                        icon="fa-regular fa-pen-to-square"
                                    ></font-awesome-icon>
                                    <p>
                                        {{ $t('common.edit') }}
                                    </p>
                                </div>

                                <div v-if="has_manager_ads">
                                    <div
                                        v-if="
                                            current_package &&
                                            current_package.extra_data
                                                .clone_campaign
                                        "
                                        class="hover:underline flex items-center space-x-1 cursor-pointer"
                                        @click="
                                            ;(is_copy_dialog_displayed = true),
                                                (active_item_for_copy =
                                                    scope.row.campaign)
                                        "
                                    >
                                        <font-awesome-icon
                                            icon="fa-regular fa-copy"
                                        />
                                        <p>
                                            {{ $t('common.copy') }}
                                        </p>
                                    </div>

                                    <div v-else>
                                        <el-tooltip
                                            class="item"
                                            effect="dark"
                                            :content="
                                                $t('common.feature_must_buy')
                                            "
                                            placement="top"
                                        >
                                            <div
                                                class="opacity-60 flex items-center space-x-1 cursor-not-allowed"
                                            >
                                                <font-awesome-icon
                                                    icon="fa-regular fa-copy"
                                                />
                                                <p>
                                                    {{ $t('common.copy') }}
                                                </p>
                                            </div>
                                        </el-tooltip>
                                    </div>
                                </div>

                                <div v-if="has_manager_ads">
                                    <div
                                        v-if="
                                            current_package &&
                                            current_package.extra_data
                                                .delete_campaign
                                        "
                                        class="hover:underline text-red flex items-center space-x-1 cursor-pointer"
                                        @click="
                                            handleDeleteCampaign(
                                                scope.row.campaign.campaign_id
                                            )
                                        "
                                    >
                                        <font-awesome-icon
                                            icon="fa-regular fa-trash-alt"
                                        />
                                        <p>
                                            {{ $t('button.delete') }}
                                        </p>
                                    </div>

                                    <div v-else>
                                        <el-tooltip
                                            class="item"
                                            effect="dark"
                                            :content="
                                                $t('common.feature_must_buy')
                                            "
                                            placement="top"
                                        >
                                            <div
                                                class="opacity-60 flex items-center space-x-1 cursor-not-allowed"
                                            >
                                                <font-awesome-icon
                                                    icon="fa-regular fa-trash-alt"
                                                />
                                                <p>
                                                    {{ $t('button.delete') }}
                                                </p>
                                            </div>
                                        </el-tooltip>
                                    </div>
                                </div>
                            </div>

                            <div class="flex space-x-2">
                                <el-tag
                                    v-if="
                                        scope.row.auto_ads &&
                                        scope.row.auto_ads.campaign_id
                                    "
                                    class="cursor-pointer"
                                    :type="
                                        scope.row.auto_ads.status === 'ENABLE'
                                            ? 'success'
                                            : 'info'
                                    "
                                    size="mini"
                                    @click="handleEditAutoAds(scope.row)"
                                >
                                    {{ $t('page.campaign.auto_ads') }}
                                </el-tag>
                                <el-tag
                                    v-if="
                                        scope.row.live_ads &&
                                        scope.row.live_ads.campaign_id
                                    "
                                    class="cursor-pointer"
                                    :type="
                                        scope.row.live_ads.status === 'ENABLE'
                                            ? 'success'
                                            : 'info'
                                    "
                                    size="mini"
                                    @click="handleEditLiveAds(scope.row)"
                                >
                                    {{ $t('page.campaign.live_ads') }}
                                </el-tag>
                            </div>
                        </div>
                    </template>
                </el-table-column>

                <el-table-column
                    width="160"
                    :show-overflow-tooltip="true"
                    label="ID"
                >
                    <template #default="scope">
                        <p>
                            {{ scope.row.id }}
                        </p>
                    </template>
                </el-table-column>

                <el-table-column :label="$t('common.status')" width="160px">
                    <template #default="scope">
                        <div v-if="!scope.row.is_child">
                            <div class="flex items-center space-x-2">
                                <div
                                    class="w-2 h-2 rounded-full"
                                    :style="{
                                        background:
                                            scope.row.campaign
                                                .secondary_status ===
                                            'CAMPAIGN_STATUS_ENABLE'
                                                ? '#26cd26'
                                                : 'darkgrey'
                                    }"
                                ></div>
                                <p class="font-medium">
                                    {{
                                        renderLabelBySecondaryStatus(
                                            scope.row.campaign.secondary_status
                                        )
                                    }}
                                </p>
                            </div>
                            <el-tooltip
                                effect="dark"
                                :content="
                                    renderDescriptionBySecondaryStatus(
                                        scope.row.campaign.secondary_status
                                    )
                                "
                                placement="top"
                            >
                                <p
                                    class="text-desc-text text-xs truncate w-[160px]"
                                >
                                    {{
                                        renderDescriptionBySecondaryStatus(
                                            scope.row.campaign.secondary_status
                                        )
                                    }}
                                </p>
                            </el-tooltip>
                        </div>
                    </template>
                </el-table-column>

                <el-table-column
                    width="160"
                    :show-overflow-tooltip="true"
                    prop="campaign.objective_type"
                    :label="$t('page.campaign.objective_type')"
                >
                    <template #default="scope">
                        <p v-if="!scope.row.is_child">
                            {{
                                p__formatObjectiveType(
                                    scope.row.campaign.objective_type
                                )
                            }}
                        </p>
                    </template>
                </el-table-column>

                <el-table-column
                    prop="campaign.budget"
                    :label="$t('common.budget')"
                    width="120px"
                >
                    <template #default="scope">
                        <div v-if="!scope.row.is_child">
                            <p
                                v-if="
                                    scope.row.campaign.budget_mode ===
                                        'BUDGET_MODE_INFINITE' ||
                                    scope.row.campaign.objective_type ===
                                        'SHOP_PURCHASES'
                                "
                                class="font-semibold"
                            >
                                {{
                                    p__renderMoneyByCurrency(
                                        scope.row.campaign.budget,
                                        scope.row.currency
                                    )
                                }}
                            </p>
                            <p
                                v-else
                                class="font-semibold cursor-pointer"
                                @click="
                                    handleSelectRowToEditBudget(
                                        scope.row.campaign
                                    )
                                "
                            >
                                {{
                                    p__renderMoneyByCurrency(
                                        scope.row.campaign.budget,
                                        scope.row.currency
                                    )
                                }}

                                <i
                                    class="el-icon-edit text-xs font-semibold"
                                ></i>
                            </p>

                            <p class="component-text-desc">
                                {{
                                    p__formatBudgetMode(
                                        scope.row.campaign.budget_mode
                                    )
                                }}
                            </p>
                        </div>
                    </template>
                </el-table-column>

                <el-table-column
                    v-for="(item, index) in m__selected_columns"
                    :key="index"
                    :show-overflow-tooltip="true"
                    :width="m__getContentWidthColumn(item)"
                    :prop="item"
                    :label="$t(`page.campaign.column_${item}`)"
                    sortable="custom"
                    align="right"
                >
                    <template v-slot:header>
                        <el-tooltip
                            :content="$t(`page.campaign.column_${item}`)"
                            effect="dark"
                            placement="top"
                        >
                            <div slot="content" class="min-w-[240px]">
                                <p
                                    class="text-sm"
                                    v-html="$t(`page.campaign.column_${item}`)"
                                ></p>

                                <p
                                    v-if="
                                        $t(
                                            `page.campaign.description_column_${item}`
                                        ) !==
                                        `page.campaign.description_column_${item}`
                                    "
                                    class="mt-1"
                                    v-html="
                                        $t(
                                            `page.campaign.description_column_${item}`
                                        )
                                    "
                                ></p>
                            </div>
                            <p>
                                {{ $t(`page.campaign.column_${item}`) }}
                            </p>
                        </el-tooltip>
                    </template>

                    <template #default="scope">
                        <div class="flex items-center justify-end space-x-1">
                            <!-- <div v-if="scope.row._result_compare_last">
                                <svg
                                    v-if="
                                        scope.row._result_compare_last[item] ===
                                        'up'
                                    "
                                    stroke="currentColor"
                                    fill="currentColor"
                                    stroke-width="0"
                                    viewBox="0 0 448 512"
                                    height="1em"
                                    width="1em"
                                    xmlns="http://www.w3.org/2000/svg"
                                    class="text-success"
                                >
                                    <path
                                        d="M34.9 289.5l-22.2-22.2c-9.4-9.4-9.4-24.6 0-33.9L207 39c9.4-9.4 24.6-9.4 33.9 0l194.3 194.3c9.4 9.4 9.4 24.6 0 33.9L413 289.4c-9.5 9.5-25 9.3-34.3-.4L264 168.6V456c0 13.3-10.7 24-24 24h-32c-13.3 0-24-10.7-24-24V168.6L69.2 289.1c-9.3 9.8-24.8 10-34.3.4z"
                                    ></path>
                                </svg>

                                <svg
                                    v-else-if="
                                        scope.row._result_compare_last[item] ===
                                        'down'
                                    "
                                    stroke="currentColor"
                                    fill="currentColor"
                                    stroke-width="0"
                                    viewBox="0 0 448 512"
                                    height="1em"
                                    width="1em"
                                    xmlns="http://www.w3.org/2000/svg"
                                    class="text-red"
                                >
                                    <path
                                        d="M413.1 222.5l22.2 22.2c9.4 9.4 9.4 24.6 0 33.9L241 473c-9.4 9.4-24.6 9.4-33.9 0L12.7 278.6c-9.4-9.4-9.4-24.6 0-33.9l22.2-22.2c9.5-9.5 25-9.3 34.3.4L184 343.4V56c0-13.3 10.7-24 24-24h32c13.3 0 24 10.7 24 24v287.4l114.8-120.5c9.3-9.8 24.8-10 34.3-.4z"
                                    ></path>
                                </svg>

                                <p
                                    v-else-if="
                                        scope.row._result_compare_last[item] ===
                                        'equal'
                                    "
                                >
                                    ---
                                </p>
                            </div> -->

                            <p>
                                {{
                                    m__formatterColumn(
                                        scope.row[item],
                                        item,
                                        scope.row.currency
                                    )
                                }}
                            </p>
                        </div>
                        <div>
                            <p
                                v-if="scope.row._compare && switch_compare"
                                class="text-desc-text"
                            >
                                {{
                                    m__formatterColumn(
                                        scope.row._compare[item],
                                        item,
                                        scope.row.currency
                                    )
                                }}
                            </p>
                        </div>
                    </template>
                </el-table-column>
            </el-table>

            <div
                v-if="!m__data_for_pagination.length"
                class="top-1/2 left-1/2 absolute -translate-x-1/2 -translate-y-1/2"
            >
                <div class="util-flex-center flex-col">
                    <img
                        class="w-[140px] h-[140px]"
                        :src="require('@/assets/images/no_data.png')"
                    />
                    <p class="text-desc-text mt-2 text-sm">
                        {{ $t('common.no_data') }}
                    </p>
                </div>
            </div>
        </div>

        <div class="flex mt-2 space-x-2">
            <div class="!ml-auto"></div>
            <div class="flex items-center cursor-pointer">
                <el-switch
                    v-model="m__auto_refresh_config.auto_refresh"
                    @change="m__handleToggleAutoRefresh"
                ></el-switch>
                <el-tooltip
                    effect="dark"
                    :content="$t('page.campaign.desc_auto_refresh')"
                    placement="top"
                >
                    <p
                        class="mx-1 text-xs"
                        @click="m__show_config_auto_refresh_dialog = true"
                    >
                        {{ $t('page.campaign.auto_refresh') }}
                    </p>
                </el-tooltip>

                <i
                    class="el-icon-edit text-xs font-semibold"
                    @click="m__show_config_auto_refresh_dialog = true"
                ></i>
            </div>

            <el-select
                v-model="m__page_info.page_size"
                filterable
                class="w-[120px]"
                size="small"
                @change="
                    m__handleChangePagesize(m__getReportCampaignsByAccount)
                "
            >
                <el-option
                    v-for="item in m__list_pagesize"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                >
                </el-option>
            </el-select>

            <el-input-number
                v-model="m__page_info.page"
                class="w-[120px]"
                size="small"
                :min="1"
                :max="m__page_info.total_page"
                @change="m__getReportCampaignsByAccount"
            ></el-input-number>

            <el-button
                icon="el-icon-refresh"
                size="small"
                @click="m__getReportCampaignsByAccount"
            ></el-button>
        </div>

        <!-- <div class="mt-4">
            <ChartWrapper
                :data_list="m__data_for_pagination"
                :selection="multiple_selection_inline"
            ></ChartWrapper>
        </div> -->

        <CopyDrawer
            :visible.sync="is_copy_dialog_displayed"
            :currency="currency"
            :tiktok_account_id="tiktok_account_id"
            :item="active_item_for_copy"
            :ranger_date="ranger_date"
            :advertiser_id="advertiser_id"
            :filtering="filtering"
            :belong_to_atosa="belong_to_atosa"
            :ads_accounts="ads_accounts"
        ></CopyDrawer>

        <ViewDataDrawer
            :visible.sync="is_view_data_drawer_displayed"
            :selected_item="active_item_for_view_data"
            :tiktok_account_id="tiktok_account_id"
            :advertiser_id="advertiser_id"
            :ranger_date="ranger_date"
            :belong_to_atosa="belong_to_atosa"
            type="campaign_id"
        ></ViewDataDrawer>

        <CreateAutomaticRuleDrawer
            :visible.sync="is_create_automatic_rule_drawer_displayed"
            :tiktok_account_id="tiktok_account_id"
            :advertiser_id="advertiser_id"
            :currency="currency"
            :multiple_selection="multiple_selection_inline"
            type="CAMPAIGN"
        ></CreateAutomaticRuleDrawer>

        <ManageAutomaticRuleDrawer
            :visible.sync="is_manage_automatic_rule_drawer_displayed"
            :tiktok_account_id="tiktok_account_id"
            :advertiser_id="advertiser_id"
            :currency="currency"
        ></ManageAutomaticRuleDrawer>

        <EditDrawer
            :visible.sync="is_edit_drawer_displayed"
            :selected_item="active_item_for_edit"
            :tiktok_account_id="tiktok_account_id"
            :min_budget_campaign="min_budget_campaign"
            :advertiser_id="advertiser_id"
            :ads_account_name="ads_account_name"
            :tiktok_business_user_name="tiktok_business_user_name"
            :belong_to_atosa="belong_to_atosa"
            :currency="
                active_item_for_edit
                    ? active_item_for_edit.currency
                    : p__default_currency_app
            "
        ></EditDrawer>

        <ConfigAutoAdsDialog
            :edit_auto_ads="edit_auto_ads"
            :tiktok_account_id="tiktok_account_id"
            :advertiser_id="advertiser_id"
            :campaign_ids="campaign_ids_for_auto_ads"
            :currency="
                multiple_selection_inline.length
                    ? multiple_selection_inline[0].currency
                    : p__default_currency_app
            "
            :visible.sync="is_config_auto_ads_dialog_displayed"
        ></ConfigAutoAdsDialog>

        <ConfigLiveAdsDialog
            :edit_live_ads="edit_live_ads"
            :tiktok_account_id="tiktok_account_id"
            :advertiser_id="advertiser_id"
            :campaign_ids="campaign_ids_for_live_ads"
            :currency="
                multiple_selection_inline.length
                    ? multiple_selection_inline[0].currency
                    : p__default_currency_app
            "
            :visible.sync="is_config_live_ads_dialog_displayed"
        ></ConfigLiveAdsDialog>

        <WarnCreateCampaignDialog
            :visible.sync="show_warn_create_campaign_dialog"
        ></WarnCreateCampaignDialog>

        <CustomColumnDialog
            :column_values.sync="m__performance_column_values"
            :visible.sync="show_custom_column_dialog"
        ></CustomColumnDialog>

        <ShowEditMultiNameDrawer
            :visible.sync="show_edit_multi_name_dialog"
            :multiple_selection="multiple_selection_inline"
            :ads_account_name="ads_account_name"
            :tiktok_business_user_name="tiktok_business_user_name"
            :advertiser_id="advertiser_id"
            :tiktok_account_id="tiktok_account_id"
            :belong_to_atosa="belong_to_atosa"
        ></ShowEditMultiNameDrawer>

        <el-dialog
            :visible.sync="visible_edit_name_dialog"
            width="400px"
            class="el-dialog-center"
            :show-close="true"
            :append-to-body="true"
            :close-on-click-modal="false"
        >
            <template slot="title">
                <p class="w-4/5 text-base font-semibold text-left truncate">
                    {{ `${$t('common.edit_name')}` }}
                </p>
                <p
                    v-if="selected_row_to_edit_name"
                    class="text-desc-text w-4/5 text-xs truncate"
                >
                    #{{ selected_row_to_edit_name.campaign_id }}
                </p>
            </template>

            <div v-if="selected_row_to_edit_name">
                <el-input
                    v-model="selected_row_to_edit_name.campaign_name"
                    size="small"
                    :placeholder="$t('input.placeholder.please_input')"
                ></el-input>
            </div>

            <template slot="footer">
                <div
                    v-if="selected_row_to_edit_name"
                    class="flex items-center justify-end"
                >
                    <el-button
                        size="small"
                        @click="visible_edit_name_dialog = false"
                    >
                        {{ $t('button.close') }}
                    </el-button>
                    <el-button
                        type="primary"
                        size="small"
                        icon="el-icon-check"
                        :disabled="!selected_row_to_edit_name.campaign_name"
                        @click="handleConfirmEditNameRow"
                    >
                        {{ $t('button.confirm') }}
                    </el-button>
                </div>
            </template>
        </el-dialog>

        <el-dialog
            :visible.sync="visible_edit_budget_dialog"
            width="280px"
            class="el-dialog-center"
            :show-close="true"
            :append-to-body="true"
            :close-on-click-modal="false"
        >
            <template slot="title">
                <p class="w-4/5 text-base font-semibold text-left truncate">
                    {{ `${$t('common.edit_budget')}` }}
                </p>
                <p
                    v-if="selected_row_to_edit_name"
                    class="text-desc-text w-4/5 text-xs truncate"
                >
                    #{{ selected_row_to_edit_name.campaign_id }}
                </p>
            </template>

            <div v-if="selected_row_to_edit_name">
                <CustomCommasNumber
                    :num.sync="selected_row_to_edit_name.budget"
                ></CustomCommasNumber>
            </div>

            <template slot="footer">
                <div
                    v-if="selected_row_to_edit_name"
                    class="flex items-center justify-end"
                >
                    <el-button
                        size="small"
                        @click="visible_edit_budget_dialog = false"
                    >
                        {{ $t('button.close') }}
                    </el-button>
                    <el-button
                        type="primary"
                        size="small"
                        icon="el-icon-check"
                        :loading="m__loading"
                        @click="handleConfirmEditBudget"
                    >
                        {{ $t('button.confirm') }}
                    </el-button>
                </div>
            </template>
        </el-dialog>

        <el-dialog
            :visible.sync="m__show_config_auto_refresh_dialog"
            width="260px"
            class="el-dialog-center"
            :show-close="true"
            :append-to-body="true"
            :close-on-click-modal="false"
        >
            <template slot="title">
                <p class="w-4/5 text-base font-semibold text-left truncate">
                    {{ $t('common.config_auto_refresh') }}
                </p>
            </template>

            <div class="flex items-center cursor-pointer">
                <el-switch
                    v-model="m__auto_refresh_config.auto_refresh"
                    @change="m__handleToggleAutoRefresh"
                ></el-switch>
                <p
                    class="mx-1 text-xs"
                    @click="m__show_config_auto_refresh_dialog = true"
                >
                    {{ $t('page.campaign.auto_refresh') }}
                </p>
            </div>

            <div class="flex flex-col mt-2">
                <p class="mb-1">{{ $t('common.refresh_second') }}</p>
                <div>
                    <el-input-number
                        v-model="m__auto_refresh_config.seconds"
                        :disabled="!m__auto_refresh_config.auto_refresh"
                        controls-position="right"
                        size="mini"
                        :min="60"
                        @change="m__handleChangeSecondAutoRefresh"
                    ></el-input-number>
                    <span>
                        {{ $t('common.second') }}
                    </span>
                </div>

                <p class="mt-2 mb-1">{{ $t('common.config') }}</p>
                <el-radio
                    v-model="m__auto_refresh_config.never_stop"
                    :disabled="!m__auto_refresh_config.auto_refresh"
                    :label="true"
                    @change="m__handleChangeNeverstop"
                    >{{ $t('common.never_stop') }}</el-radio
                >
                <el-radio
                    v-model="m__auto_refresh_config.never_stop"
                    :disabled="!m__auto_refresh_config.auto_refresh"
                    :label="false"
                    @change="m__handleChangeNeverstop"
                    >{{ $t('common.stop_after') }}</el-radio
                >
                <div v-if="!m__auto_refresh_config.never_stop">
                    <el-input-number
                        v-model="m__auto_refresh_config.stop_after_minutes"
                        :disabled="!m__auto_refresh_config.auto_refresh"
                        controls-position="right"
                        size="mini"
                        :min="1"
                        @change="m__handleChangeNeverstop"
                    ></el-input-number>
                    <span>{{ $t('common.minute') }}</span>
                </div>
            </div>
        </el-dialog>

        <ShowEditMultiBudgetDrawer
            :visible.sync="show_edit_multi_budget_dialog"
            :multiple_selection="multiple_selection_inline"
            :advertiser_id="advertiser_id"
            :tiktok_account_id="tiktok_account_id"
            :belong_to_atosa="belong_to_atosa"
        ></ShowEditMultiBudgetDrawer>

        <GetCpaDrawer
            :visible.sync="show_get_cpa_drawer"
            :advertiser_id="advertiser_id"
            :ads_accounts="ads_accounts"
            :tiktok_account_id="tiktok_account_id"
            :belong_to_atosa="belong_to_atosa"
        ></GetCpaDrawer>

        <ProposalDialog
            :visible.sync="show_proposal_dialog"
            :tiktok_account_id="tiktok_account_id"
            :advertiser_id="advertiser_id"
            :ranger_date="ranger_date"
            :device_model_options="device_model_options"
            :interest_category_options="interest_category_options"
        >
        </ProposalDialog>
    </section>
</template>

<script>
import campaignMixin from '../_mixin'
import CopyDrawer from './copy-drawer'
import EditDrawer from './edit-drawer'
import ViewDataDrawer from '@/views/campaign/campaign-wrapper/_components/view-data-drawer'
import { updateCampaign } from '@/services/atosa-tiktok-ads/campaign'
import { getAllConfigAds } from '@/services/atosa-tiktok-ads/auto-ads'
import { getConfigAdsLives } from '@/services/atosa-tiktok-ads/reporting'
import { updateCampaignStatus } from '@/services/atosa-tiktok-ads/campaign'
import ConfigAutoAdsDialog from './config-auto-ads-dialog'
import ConfigLiveAdsDialog from './config-live-ads-dialog'
import WarnCreateCampaignDialog from './warn-create-campaign-dialog'
import CustomColumnDialog from '@/views/campaign/campaign-wrapper/_components/custom-column-dialog'
import CreateAutomaticRuleDrawer from '@/views/campaign/campaign-wrapper/_components/atosa-create-automatic-rule-drawer'
import ManageAutomaticRuleDrawer from '@/views/campaign/campaign-wrapper/_components/atosa-manage-automatic-rule-drawer'
import ProposalDialog from '@/views/dashboard/overview/chart-wrapper/proposal-dialog'
import ExcelSvg from '@/assets/svgs/excel.svg'
import ShowEditMultiNameDrawer from './show-edit-multi-name-drawer'
import ShowEditMultiBudgetDrawer from './show-edit-multi-budget-drawer'
import GetCpaDrawer from './get-cpa-drawer'
import {
    getAllDeviceModels,
    getQueryTool
} from '@/services/atosa-tiktok-ads/tool'
// import ChartWrapper from './chart-wrapper'

export default {
    components: {
        ExcelSvg,
        EditDrawer,
        CreateAutomaticRuleDrawer,
        ManageAutomaticRuleDrawer,
        ShowEditMultiNameDrawer,
        CopyDrawer,
        ViewDataDrawer,
        CustomColumnDialog,
        WarnCreateCampaignDialog,
        ConfigAutoAdsDialog,
        ShowEditMultiBudgetDrawer,
        // ChartWrapper,
        ConfigLiveAdsDialog,
        GetCpaDrawer,
        ProposalDialog
    },

    mixins: [campaignMixin],

    props: [
        'ads_accounts',
        'min_budget_campaign',
        'ads_account_name',
        'tiktok_business_user_name'
    ],

    data() {
        return {
            show_get_cpa_drawer: false,
            show_edit_multi_budget_dialog: false,
            show_edit_multi_name_dialog: false,
            visible_edit_budget_dialog: false,
            active_item_for_view_data: null,
            is_create_automatic_rule_drawer_displayed: false,
            is_manage_automatic_rule_drawer_displayed: false,
            is_view_data_drawer_displayed: false,
            active_item_for_edit: null,
            is_edit_drawer_displayed: false,
            selected_row_to_edit_name: null,
            visible_edit_name_dialog: false,
            show_custom_column_dialog: false,
            show_warn_create_campaign_dialog: false,
            has_show_warn_create_campaign_dialog: false,
            edit_auto_ads: null,
            is_config_auto_ads_dialog_displayed: false,
            active_item_for_copy: null,
            is_copy_dialog_displayed: false,
            edit_live_ads: null,
            is_config_live_ads_dialog_displayed: false,
            show_proposal_dialog: false,
            interest_category_options: [],
            device_model_options: []
        }
    },

    computed: {
        multiple_selection_inline: {
            get() {
                return this.multiple_selection
            },
            set(value) {
                this.$emit('update:multiple_selection', value)
            }
        },

        campaign_ids_for_auto_ads() {
            if (this.edit_auto_ads) {
                return [this.edit_auto_ads.campaign.campaign_id]
            }

            if (this.multiple_selection_inline.length) {
                return this.multiple_selection_inline.map(
                    (item) => item.campaign.campaign_id
                )
            }

            return []
        },

        campaign_ids_for_live_ads() {
            if (this.edit_live_ads) {
                return [this.edit_live_ads.campaign.campaign_id]
            }

            if (this.multiple_selection_inline.length) {
                return this.multiple_selection_inline.map(
                    (item) => item.campaign.campaign_id
                )
            }

            return []
        }
    },

    watch: {
        is_config_auto_ads_dialog_displayed() {
            if (!this.is_config_auto_ads_dialog_displayed) {
                this.edit_auto_ads = null
            }
        },

        is_config_live_ads_dialog_displayed() {
            if (!this.is_config_live_ads_dialog_displayed) {
                this.edit_live_ads = null
            }
        },

        m__data_for_pagination() {
            if (
                !this.has_show_warn_create_campaign_dialog &&
                !this.m__data_for_pagination.length &&
                this.m__page_info
            ) {
                this.show_warn_create_campaign_dialog = true
            }

            this.has_show_warn_create_campaign_dialog = true
        },

        multiple_selection() {
            if (!this.multiple_selection_inline.length) {
                this.$refs.multipleTable.clearSelection([])
            }
        }
    },

    async mounted() {
        this.m__type = 'campaign_id'
        await this.m__getReportCampaignsByAccount()

        this.p__event_bus.$on(
            'eb_reload_campaign',
            this.m__getReportCampaignsByAccount
        )

        const temp = localStorage.getItem(
            `performance_column_values_${this.m__type}`
        )
        if (temp && temp.length) {
            this.m__performance_column_values = JSON.parse(temp)
        } else {
            this.m__performance_column_values = [
                {
                    value: 'BASIC_DATA',
                    child_values: this.p__basic_metrics
                }
            ]
        }
    },

    destroyed() {
        this.p__event_bus.$off(
            'eb_reload_campaign',
            this.m__getReportCampaignsByAccount
        )
    },

    methods: {
        async getDeviceModelOptions() {
            const response = await getAllDeviceModels()
            this.device_model_options = response.data
        },

        async getInterestCategoryOptions() {
            if (this.tiktok_account_id) {
                const response = await getQueryTool(this.tiktok_account_id, {
                    path: 'interest_category/',
                    params: {
                        advertiser_id: this.ads_account.advertiser_id,
                        language: this.$i18n.locale
                    }
                })
                this.interest_category_options =
                    response.data.interest_categories
            }
        },
        async handleConfirmEditBudget() {
            this.m__loading = true

            try {
                await updateCampaign(this.tiktok_account_id, {
                    advertiser_id: this.advertiser_id,
                    budget: this.selected_row_to_edit_name.budget,
                    campaign_id: this.selected_row_to_edit_name.campaign_id,
                    campaign_name: this.selected_row_to_edit_name.campaign_name,
                    belong_to_atosa: this.belong_to_atosa
                })

                this.m__getReportCampaignsByAccount()

                this.visible_edit_budget_dialog = false

                this.p__showNotify(
                    'success',
                    this.$t('common.update_data_success')
                )
            } catch (error) {
                console.error(error)
            }

            this.m__loading = false
        },

        handleRedirectToCreateCampaign() {
            this.$router.push({
                path: `${this.p__group_route.ads_manager}/${this.p__route.create_campaign}?advertiser_id=${this.advertiser_id}`
            })
            // if (this.multiple_selection_inline.length !== 1) {
            //     this.$router.push({
            //         path: `${this.p__group_route.ads_manager}/${this.p__route.create_campaign}`
            //     })
            //     return
            // }

            // this.$router.push({
            //     path: `${this.p__group_route.ads_manager}/${this.p__route.create_campaign}?campaign_id=${this.multiple_selection_inline[0].campaign.campaign_id}`
            // })
        },

        renderDescriptionBySecondaryStatus(status) {
            if (
                [
                    'CAMPAIGN_STATUS_ENABLE',
                    'CAMPAIGN_STATUS_DISABLE',
                    'CAMPAIGN_STATUS_DELETE'
                ].some((item) => item === status)
            ) {
                return ''
            }

            if (status === 'CAMPAIGN_STATUS_ADVERTISER_AUDIT_DENY') {
                return this.$t(
                    'page.campaign.campaign_status_account_not_approved'
                )
            }

            if (status === 'CAMPAIGN_STATUS_ADVERTISER_AUDIT') {
                return this.$t(
                    'page.campaign.campaign_status_account_in_review'
                )
            }

            if (status === 'ADVERTISER_CONTRACT_PENDING') {
                return this.$t(
                    'page.campaign.campaign_status_contract_has_not_taken_effect'
                )
            }

            if (status === 'ADVERTISER_ACCOUNT_PUNISH') {
                return this.$t(
                    'page.campaign.campaign_status_account_penalized'
                )
            }

            if (status === 'CAMPAIGN_STATUS_BUDGET_EXCEED') {
                return this.$t(
                    'page.campaign.campaign_status_campaign_out_of_budget'
                )
            }

            return status
        },

        renderLabelBySecondaryStatus(status) {
            if (!status) {
                return this.$t('common.not_delivering')
            }

            if (status === 'CAMPAIGN_STATUS_ENABLE') {
                return this.$t('common.active')
            }

            if (status === 'CAMPAIGN_STATUS_DISABLE') {
                return this.$t('common.inactive')
            }

            if (
                [
                    'CAMPAIGN_STATUS_BUDGET_EXCEED',
                    'ADVERTISER_ACCOUNT_PUNISH',
                    'ADVERTISER_CONTRACT_PENDING',
                    'CAMPAIGN_STATUS_ADVERTISER_AUDIT',
                    'CAMPAIGN_STATUS_ADVERTISER_AUDIT_DENY'
                ].some((item) => item === status)
            ) {
                return this.$t('common.not_delivering')
            }

            if (status === 'CAMPAIGN_STATUS_DELETE') {
                return this.$t('common.deleted')
            }

            return status
        },

        handleOpenViewDataDrawer(row) {
            this.active_item_for_view_data = { ...row }
            this.is_view_data_drawer_displayed = true
        },

        handleOpenEditDrawer(row) {
            this.active_item_for_edit = { ...row }
            this.is_edit_drawer_displayed = true
        },

        handleSelectRowToEditName(row) {
            this.selected_row_to_edit_name = { ...row }
            this.visible_edit_name_dialog = true
        },

        handleSelectRowToEditBudget(row) {
            this.visible_edit_budget_dialog = true
            this.selected_row_to_edit_name = { ...row }
        },

        async handleConfirmEditNameRow() {
            try {
                await updateCampaign(this.tiktok_account_id, {
                    advertiser_id: this.advertiser_id,
                    campaign_name: this.selected_row_to_edit_name.campaign_name,
                    campaign_id: this.selected_row_to_edit_name.campaign_id,
                    belong_to_atosa: this.belong_to_atosa
                })

                this.m__getReportCampaignsByAccount()
                this.visible_edit_name_dialog = false

                this.p__showNotify(
                    'success',
                    this.$t('common.update_data_success')
                )
            } catch (error) {
                console.error(error)
            }
        },

        handleSelectRow(row) {
            this.handle_select_row(row.campaign.campaign_id)

            this.multiple_selection_inline = [row]
            this.toggleSelection([row])
        },

        toggleSelection(rows) {
            this.$refs.multipleTable.clearSelection()

            if (rows) {
                rows.forEach((row) => {
                    this.$refs.multipleTable.toggleRowSelection(row)
                })
            }
        },

        handleSelectionChange(val) {
            this.multiple_selection_inline = val
        },

        handleEditAutoAds(row) {
            this.edit_auto_ads = row
            this.is_config_auto_ads_dialog_displayed = true
        },

        handleEditLiveAds(row) {
            this.edit_live_ads = row
            this.is_config_live_ads_dialog_displayed = true
        },

        async fetchConfigAds() {
            const campaign_ids = this.m__data_for_pagination.map(
                (item) => item.campaign.campaign_id
            )
            if (campaign_ids.length) {
                const response = await getAllConfigAds(this.tiktok_account_id, {
                    advertiser_id: this.advertiser_id,
                    campaign_ids
                })

                for (const item of response.data) {
                    this.m__data_for_pagination =
                        this.m__data_for_pagination.map((it) => {
                            if (it.campaign.campaign_id === item.campaign_id) {
                                return {
                                    ...it,
                                    auto_ads: item
                                }
                            }

                            return it
                        })
                }
            }
        },

        async handleChangeStatusCampaign(campaign_id) {
            const camp = this.m__data_for_pagination.find(
                (item) => item.campaign.campaign_id === campaign_id
            )

            if (!camp) {
                return
            }

            camp.campaign.operation_status =
                camp.campaign.operation_status === 'ENABLE'
                    ? 'DISABLE'
                    : 'ENABLE'
            try {
                await updateCampaignStatus(this.tiktok_account_id, {
                    advertiser_id: this.advertiser_id,
                    campaign_ids: [campaign_id],
                    operation_status: camp.campaign.operation_status,
                    belong_to_atosa: this.belong_to_atosa
                })

                this.p__showNotify(
                    'success',
                    this.$t('page.campaign.message_update_status_xxx_success', {
                        xxx: this.$t(`common.campaign`)
                    })
                )

                setTimeout(() => {
                    this.m__getReportCampaignsByAccount()
                }, 1500)
            } catch (error) {
                camp.campaign.operation_status =
                    camp.campaign.operation_status === 'ENABLE'
                        ? 'DISABLE'
                        : 'ENABLE'

                console.error(error)

                this.p__showNotify(
                    'error',
                    this.$t('page.campaign.message_update_status_xxx_failed', {
                        xxx: this.$t(`common.campaign`)
                    })
                )
            }
        },

        async handleDeleteCampaign(campaign_id) {
            try {
                await this.$confirm(
                    this.$t('common.delete_data_confirm'),
                    this.$t('common.warning'),
                    {
                        confirmButtonText: this.$t('button.confirm'),
                        cancelButtonText: this.$t('button.close'),
                        type: 'warning'
                    }
                )
            } catch (e) {
                return
            }

            const camp = this.m__data_for_pagination.find(
                (item) => item.campaign.campaign_id === campaign_id
            )

            if (!camp) {
                return
            }

            try {
                await updateCampaignStatus(this.tiktok_account_id, {
                    advertiser_id: this.advertiser_id,
                    campaign_ids: [campaign_id],
                    operation_status: 'DELETE',
                    type: 'button',
                    belong_to_atosa: this.belong_to_atosa
                })
                camp.campaign.secondary_status = 'CAMPAIGN_STATUS_DELETE'

                this.p__event_bus.$emit('eb_reload_campaign')
                this.p__event_bus.$emit('eb_reload_adgroup')
                this.p__event_bus.$emit('eb_reload_ad')

                this.p__showNotify(
                    'success',
                    this.$t('page.campaign.message_delete_xxx_success', {
                        xxx: this.$t(`common.campaign`)
                    })
                )
            } catch (error) {
                console.error(error)

                camp.campaign.secondary_status = 'CAMPAIGN_STATUS_DISABLE'

                this.p__showNotify(
                    'error',
                    this.$t('page.campaign.message_delete_xxx_failed', {
                        xxx: this.$t(`common.campaign`)
                    })
                )
            }
        },

        async fetchConfigAdsLives() {
            const campaign_ids = this.m__data_for_pagination.map(
                (item) => item.campaign.campaign_id
            )
            if (campaign_ids.length) {
                const response = await getConfigAdsLives(
                    this.tiktok_account_id,
                    {
                        advertiser_id: this.advertiser_id,
                        campaign_ids
                    }
                )

                for (const item of response.data) {
                    this.m__data_for_pagination =
                        this.m__data_for_pagination.map((it) => {
                            if (it.campaign.campaign_id === item.campaign_id) {
                                return {
                                    ...it,
                                    live_ads: item
                                }
                            }

                            return it
                        })
                }
            }
        }
    }
}
</script>
