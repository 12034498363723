import request from './_config'

const path = `customer_portrait`

export function getCustomerPortraitFile() {
    return request({
        url: `${path}/file`,
        method: 'get',
        params: {}
    })
}

export function createCustomerPortraitFile(data) {
    return request({
        url: `${path}/file`,
        method: 'post',
        data: {
            name: data.name,
            description: data.description,
            data: data.data
        }
    })
}

export function updateCustomerPortraitFile(data) {
    return request({
        url: `${path}/file`,
        method: 'put',
        data: {
            id: data.id,
            name: data.name,
            description: data.description,
            data: data.data
        }
    })
}

export function deleteCustomerPortraitReport(id) {
    return request({
        url: `${path}/file`,
        method: 'delete',
        params: {
            id: id
        }
    })
}
