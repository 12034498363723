import request from './_config'

const path = `keyword_tool`

export function getKeywordReport(keyword) {
    return request({
        url: `${path}/get-report-from-tiktok`,
        method: 'get',
        params: { keyword }
    })
}

export function getKeywordFile() {
    return request({
        url: `${path}/keyword_file`,
        method: 'get',
        params: { }
    })
}

export function createKeywordFile(data) {
    return request({
        url: `${path}/keyword_file`,
        method: 'post',
        data: {
            name: data.name,
            description: data.description,
            data: data.data
         }
    })
}

export function updateKeywordFile(data) {
    return request({
        url: `${path}/keyword_file`,
        method: 'put',
        data: {
            keyword_file_id: data.keyword_file_id,
            name: data.name,
            description: data.description,
            data: data.data
         }
    })
}

export function deleteKeywordReport(keyword_file_id) {
    return request({
        url: `${path}/keyword_file`,
        method: 'delete',
        params: {
            keyword_file_id: keyword_file_id
         }
    })
}
