<template>
    <div class="component-box">
        <div class="mb-8">
            <div class="flex items-center justify-between">
                <p class="font-semibold">
                    {{ $t('page.campaign.targeting') }}
                </p>

                <el-button
                    size="mini"
                    class="ml-auto"
                    @click="is_show_create_target_dialog = true"
                >
                    {{ $t('button.save_target') }}
                </el-button>

                <el-button
                    size="mini"
                    class="ml-auto"
                    @click="is_list_save_target_drawer_displayed = true"
                >
                    {{ $t('button.sample_target_list') }}
                </el-button>

                <el-button
                    plain
                    class="ml-4"
                    size="small"
                    @click="show_proposal_dialog = true"
                >
                    Đề xuất chân dung KH
                </el-button>
            </div>

            <div
                v-if="selected_target"
                class="mt-2 border-border border-[1px] py-2 px-5 rounded-lg flex items-center justify-between"
            >
                <p class="text-xs font-semibold">
                    <span class="text-desc-text">{{
                        $t('common.sample')
                    }}</span>
                    :
                    {{ selected_target.custom_target_name }}
                </p>
                <el-button
                    size="mini"
                    class="ml-auto"
                    @click="handleUpdateCustomTarget()"
                >
                    {{ $t('common.edit') }}
                </el-button>
            </div>
        </div>

        <div v-if="show_targeting_mode" class="mb-6">
            <p class="component-label">
                {{ $t('page.campaign.select_target_mode') }}
            </p>
            <div class="flex space-x-6">
                <div
                    v-for="item in targeting_modes"
                    :key="item.value"
                    class="border-[1px] border-l-[4px] py-4 w-[280px] bg-bg px-6 rounded-lg cursor-pointer"
                    :class="[
                        selected_item_in_line.creative_material_mode ===
                        item.value
                            ? 'border-brand-atosa'
                            : 'hover:border-border border-transparent'
                    ]"
                    @click="
                        selected_item_in_line.creative_material_mode =
                            item.value
                    "
                >
                    <div class="flex items-center space-x-1 mb-2">
                        <i class="text-lg" :class="[item.icon]" />

                        <p class="font-semibold text-sm">
                            {{ item.label }}
                        </p>
                    </div>
                    <p class="component-text-desc">
                        {{ item.desc }}
                    </p>
                </div>
            </div>
        </div>

        <div>
            <Demographics
                v-if="
                    selected_item_in_line.creative_material_mode === 'DYNAMIC'
                "
                :objective_type="objective_type"
                :tiktok_account_id="tiktok_account_id"
                :advertiser_id="advertiser_id"
                :selected_item.sync="selected_item_in_line"
            ></Demographics>

            <el-collapse
                v-else
                v-model="toggle_collapse"
                class="custom-primary"
            >
                <el-collapse-item name="1">
                    <template slot="title">
                        <p>
                            {{ $t('page.campaign.demographics') }}
                        </p>
                    </template>

                    <Demographics
                        :objective_type="objective_type"
                        :tiktok_account_id="tiktok_account_id"
                        :advertiser_id="advertiser_id"
                        :selected_item.sync="selected_item_in_line"
                        :bc_id="bc_id"
                    ></Demographics>
                </el-collapse-item>

                <el-collapse-item name="2">
                    <template slot="title">
                        <p>{{ $t('common.audience') }}</p>
                    </template>

                    <Audience
                        :tiktok_account_id="tiktok_account_id"
                        :advertiser_id="advertiser_id"
                        :selected_item.sync="selected_item_in_line"
                    ></Audience>
                </el-collapse-item>

                <el-collapse-item name="3">
                    <template slot="title">
                        <p>
                            {{ $t('page.campaign.interests_and_behaviours') }}
                        </p>
                    </template>

                    <InterestsAndBehaviours
                        :tiktok_account_id="tiktok_account_id"
                        :advertiser_id="advertiser_id"
                        :selected_item.sync="selected_item_in_line"
                        :belong_to_atosa="belong_to_atosa"
                    ></InterestsAndBehaviours>
                </el-collapse-item>

                <el-collapse-item name="4">
                    <template slot="title">
                        <p>{{ $t('common.device') }}</p>
                    </template>

                    <Device
                        :tiktok_account_id="tiktok_account_id"
                        :objective_type="objective_type"
                        :advertiser_id="advertiser_id"
                        :selected_item.sync="selected_item_in_line"
                    ></Device>
                </el-collapse-item>
            </el-collapse>
        </div>

        <ListSaveTargetDrawer
            :visible.sync="is_list_save_target_drawer_displayed"
            :tiktok_account_id="tiktok_account_id"
            :objective_type="objective_type"
            :selected_adgroup.sync="selected_item_in_line"
            :selected_target.sync="selected_target"
        ></ListSaveTargetDrawer>

        <el-dialog
            :visible.sync="is_show_create_target_dialog"
            :close-on-click-modal="true"
            :append-to-body="true"
            width="480px"
        >
            <div slot="title">
                <p class="text-lg font-semibold">
                    {{ $t('button.save_custom_target') }}
                </p>
            </div>

            <div>
                <p class="component-label">
                    {{ $t('common.name') }}
                </p>
                <el-input
                    v-model="custom_target_name"
                    type="text"
                    size="small"
                    :placeholder="$t('input.placeholder.please_input')"
                >
                </el-input>
            </div>

            <template slot="footer">
                <div class="flex items-center justify-end mt-4">
                    <el-button
                        size="small"
                        @click="is_show_create_target_dialog = false"
                    >
                        {{ $t('button.close') }}
                    </el-button>

                    <el-button
                        :disabled="!custom_target_name"
                        type="primary"
                        size="small"
                        icon="el-icon-check"
                        @click="handleCreateCustomTarget"
                    >
                        {{ $t('button.confirm') }}
                    </el-button>
                </div>
            </template>
        </el-dialog>
        <ProposalDialog
            :visible.sync="show_proposal_dialog"
            :tiktok_account_id="tiktok_account_id"
            :advertiser_id="advertiser_id"
            :ranger_date="ranger_date"
            :device_model_options="device_model_options"
            :interest_category_options="interest_category_options"
            :type="'campaign'"
        >
        </ProposalDialog>
    </div>
</template>

<script>
import InterestsAndBehaviours from './interests-behaviours'
import Demographics from './demographics'
import Audience from './audience'
import Device from './device'
import ListSaveTargetDrawer from './list-save-target-drawer'
import {
    createCustomTarget,
    updateCustomTarget
} from '@/services/atosa-tiktok-ads/custom-target'
import ProposalDialog from '@/views/dashboard/overview/chart-wrapper/proposal-dialog'
import {
    getAllDeviceModels,
    getQueryTool
} from '@/services/atosa-tiktok-ads/tool'

export default {
    components: {
        ListSaveTargetDrawer,
        InterestsAndBehaviours,
        Audience,
        Device,
        Demographics,
        ProposalDialog
    },

    props: [
        'type_form',
        'selected_item',
        'tiktok_account_id',
        'advertiser_id',
        'bc_id',
        'belong_to_atosa',
        'objective_type'
    ],

    data() {
        return {
            show_proposal_dialog: false,
            selected_target: null,
            custom_target_name: '',
            is_show_create_target_dialog: false,
            is_list_save_target_drawer_displayed: false,
            toggle_collapse: ['1', '2', '3', '4'],
            targeting_modes: [
                {
                    label: this.$t('page.campaign.custom_targeting'),
                    desc: this.$t('page.campaign.desc_custom_targeting'),
                    icon: 'el-icon-mobile-phone',
                    value: `CUSTOM`
                },
                {
                    label: this.$t('page.campaign.automatic_targeting'),
                    desc: this.$t('page.campaign.desc_automatic_targeting'),
                    icon: 'el-icon-postcard',
                    value: `DYNAMIC`
                }
            ],
            interest_category_options: [],
            device_model_options: []
        }
    },

    computed: {
        selected_item_in_line: {
            get: function () {
                return this.selected_item
            },
            set: function (val) {
                this.$emit('update:selected_item', val)
            }
        },

        show_targeting_mode() {
            return [
                'TRAFFIC',
                'LEAD_GENERATION',
                'ENGAGEMENT',
                'APP_PROMOTION',
                'WEB_CONVERSIONS'
            ].some((item) => item === this.objective_type)
        }
    },

    watch: {
        is_show_create_target_dialog() {
            this.custom_target_name = ''
        }
    },

    methods: {
        async getDeviceModelOptions() {
            const response = await getAllDeviceModels()
            this.device_model_options = response.data
        },

        async getInterestCategoryOptions() {
            if (this.tiktok_account_id) {
                const response = await getQueryTool(this.tiktok_account_id, {
                    path: 'interest_category/',
                    params: {
                        advertiser_id: this.ads_account.advertiser_id,
                        language: this.$i18n.locale
                    }
                })
                this.interest_category_options =
                    response.data.interest_categories
            }
        },
        async handleCreateCustomTarget() {
            if (!this.custom_target_name) {
                return
            }
            try {
                const data_config = JSON.stringify(this.selected_item)

                await createCustomTarget(this.tiktok_account_id, {
                    custom_target_name: this.custom_target_name,
                    objective_type: this.objective_type,
                    custom_target_type: 'CUSTOM_TARGET',
                    data_config
                })

                this.custom_target_name = ''
                this.is_show_create_target_dialog = false

                this.p__event_bus.$emit('eb_reload_list_targets')

                this.p__showNotify(
                    'success',
                    this.$t('common.create_data_success')
                )
            } catch (error) {
                console.error(error)
            }
        },

        async handleUpdateCustomTarget() {
            if (!this.selected_target) {
                return
            }
            try {
                await this.$confirm(
                    this.$t('common.update_data_confirm'),
                    this.$t('common.warning'),
                    {
                        confirmButtonText: this.$t('button.confirm'),
                        cancelButtonText: this.$t('button.close'),
                        type: 'warning'
                    }
                )
            } catch (e) {
                return
            }

            try {
                const data_config = JSON.stringify(this.selected_item_in_line)

                await updateCustomTarget(this.tiktok_account_id, {
                    ...this.selected_target,
                    custom_target_id: this.selected_target._id,
                    data_config
                })

                this.p__event_bus.$emit('eb_reload_list_targets')

                this.p__showNotify(
                    'success',
                    this.$t('common.update_data_success')
                )
            } catch (error) {
                console.error(error)
            }
        }
    }
}
</script>
