import { removeEmptyParams } from '@/views/campaign/campaign-wrapper/_utils'

export default {
    methods: {
        m__validAd(ad, adgroup, objective_type) {
            if (!ad.ad_name) {
                this.p__showNotify(
                    'warning',
                    this.$t('page.campaign.please_input_ad_name')
                )

                document
                    .getElementById('input_ad_name_wrapper')
                    .scrollIntoView({ behavior: 'smooth' })
                document.getElementById('input_ad_name').focus()
                // document.getElementById('input_ad_name').className +=
                //     ' border-red border-[1px] rounded-[8px]'

                return false
            }

            if (objective_type === 'PRODUCT_SALES') {
                if (
                    ad.extension.shopping_ads_type === 'LIVE' &&
                    ad.extension.shopping_live_ad_setup === 'live_video'
                ) {
                    return true
                }
                if (
                    !ad.video_id &&
                    !ad.tiktok_item_id &&
                    ad.extension.shopping_ads_type === 'VIDEO'
                ) {
                    this.p__showNotify(
                        'warning',
                        this.$t('page.campaign.please_input_video')
                    )
                    return false
                }

                if (
                    ad.extension.shopping_ads_type !== 'LIVE' &&
                    ad.product_specific_type !== 'ALL' &&
                    !ad.extension.selected_products.length
                ) {
                    this.p__showNotify(
                        'warning',
                        this.$t('page.campaign.please_select_products')
                    )
                    return false
                }

                if (
                    ad.extension.shopping_ads_type === 'PRODUCT_SHOPPING_ADS' &&
                    ad.extension.selected_products.length >
                        this.p__number_max_products_in_product_shopping_ads
                ) {
                    this.p__showNotify(
                        'warning',
                        this.$t(
                            'page.campaign.please_psa_select_max_n_products',
                            {
                                n: this
                                    .p__number_max_products_in_product_shopping_ads
                            }
                        )
                    )
                    return false
                }

                if (
                    ad.extension.shopping_ads_type === 'VIDEO' &&
                    ad.extension.selected_products.length >
                        this.p__number_max_products_in_video_shopping_ads
                ) {
                    this.p__showNotify(
                        'warning',
                        this.$t(
                            'page.campaign.please_vsa_select_max_n_products',
                            {
                                n: this
                                    .p__number_max_products_in_video_shopping_ads
                            }
                        )
                    )
                    return false
                }
            }

            if (
                !ad.ad_text &&
                ad.extension.shopping_ads_type !== 'PRODUCT_SHOPPING_ADS'
            ) {
                this.p__showNotify(
                    'warning',
                    this.$t('page.campaign.please_input_ad_text')
                )
                document
                    .getElementById('input_ad_text_wrapper')
                    .scrollIntoView({ behavior: 'smooth' })
                document.getElementById('input_ad_text').focus()
                // document.getElementById('input_ad_text').className +=
                //     ' border-red border-[1px] rounded-[8px]'

                return false
            }

            if (
                ['ENGAGEMENT', 'PRODUCT_SALES'].every(
                    (type) => type !== objective_type
                ) &&
                !ad.identity_id
            ) {
                this.p__showNotify(
                    'warning',
                    this.$t('page.campaign.please_select_identity')
                )
                return false
            }

            if (
                !ad.video_id &&
                !ad.tiktok_item_id &&
                ad.extension.shopping_ads_type !== 'PRODUCT_SHOPPING_ADS'
            ) {
                this.p__showNotify(
                    'warning',
                    this.$t('page.campaign.please_input_video')
                )
                return false
            }

            // if (!ad.video_id && !ad.tiktok_item_id) {
            //     this.p__showNotify(
            //         'warning',
            //         this.$t('page.campaign.please_input_video')
            //     )
            //     return false
            // }

            if (
                ['TRAFFIC', 'WEB_CONVERSIONS'].some(
                    (type) => type === objective_type
                )
            ) {
                if (!ad.landing_page_url) {
                    this.p__showNotify(
                        'warning',
                        this.$t(
                            'page.campaign.please_input_ad_landing_page_url'
                        )
                    )

                    document
                        .getElementById('input_ad_landing_page_url_wrapper')
                        .scrollIntoView({ behavior: 'smooth' })
                    document.getElementById('input_ad_landing_page_url').focus()
                    document.getElementById(
                        'input_ad_landing_page_url'
                    ).className += ' border-red border-[1px] rounded-[8px]'

                    return false
                }

                if (!this.p__isValidUrl(ad.landing_page_url)) {
                    this.p__showNotify(
                        'warning',
                        this.$t(
                            'page.campaign.please_input_ad_format_landing_page_url'
                        )
                    )

                    document
                        .getElementById('input_ad_landing_page_url_wrapper')
                        .scrollIntoView({ behavior: 'smooth' })
                    document.getElementById('input_ad_landing_page_url').focus()
                    document.getElementById(
                        'input_ad_landing_page_url'
                    ).className += ' border-red border-[1px] rounded-[8px]'

                    return false
                }
            }

            if (
                (objective_type === 'WEB_CONVERSIONS' &&
                    adgroup.extension.selected_type_web ===
                        'TIKTOK_INSTANT_PAGE' &&
                    !ad.page_id) ||
                (objective_type === 'LEAD_GENERATION' && !ad.page_id)
            ) {
                this.p__showNotify(
                    'warning',
                    this.$t('page.campaign.please_select_instant_page')
                )

                return false
            }

            return true
        },

        m__formatAd(ad, adgroup, objective_type) {
            if (objective_type === 'PRODUCT_SALES') {
                if (!ad.identity_id) {
                    ad.identity_id = adgroup.identity_id
                    ad.identity_authorized_bc_id =
                        adgroup.identity_authorized_bc_id
                    ad.identity_type = adgroup.identity_type
                }

                if (adgroup.shopping_ads_type === 'LIVE') {
                    ad.ad_text = 'live shopping'
                    ad.creative_type =
                        ad.extension.shopping_live_ad_setup === 'live_video'
                            ? 'DIRECT_LIVE'
                            : 'SHORT_VIDEO_LIVE'
                    ad.call_to_action =
                        ad.extension.shopping_live_ad_setup === 'live_video'
                            ? ''
                            : 'WATCH_LIVE'
                    ad.ad_format =
                        ad.extension.shopping_live_ad_setup === 'live_video'
                            ? 'LIVE_CONTENT'
                            : 'SINGLE_VIDEO'
                    ad.vertical_video_strategy =
                        ad.extension.shopping_live_ad_setup === 'live_video'
                            ? 'LIVE_STREAM'
                            : 'SINGLE_VIDEO'
                } else if (adgroup.shopping_ads_type !== 'LIVE') {
                    ad.catalog_id = adgroup.catalog_id

                    ad.creative_type =
                        ad.extension.selected_products.length === 1
                            ? 'SHOP_PDP'
                            : 'SHOP_PLP'
                    if (adgroup.product_source === 'SHOWCASE') {
                        ad.showcase_products =
                            ad.extension.selected_products.map((item) => {
                                return {
                                    item_group_id: item.item_group_id,
                                    store_id: item.store_id,
                                    catalog_id: item.catalog_id
                                        ? item.catalog_id
                                        : '1'
                                }
                            })
                    } else {
                        ad.item_group_ids = ad.extension.selected_products.map(
                            (item) => item.item_group_id
                        )
                    }
                }
            }

            removeEmptyParams(ad)
        },

        m__returnAd(ad, adgroup, objective_type) {
            if (
                objective_type === 'PRODUCT_SALES' &&
                adgroup.shopping_ads_type === 'PRODUCT_SHOPPING_ADS'
            ) {
                if (ad.product_specific_type === 'CUSTOMIZED_PRODUCTS') {
                    return {
                        ad_name: ad.ad_name,
                        product_specific_type: 'CUSTOMIZED_PRODUCTS',
                        item_group_ids: ad.item_group_ids
                    }
                }
                if (ad.product_specific_type === 'ALL') {
                    return {
                        ad_name: ad.ad_name,
                        product_specific_type: 'ALL'
                    }
                }
            }

            return ad
        }
    }
}
