<template>
    <custom-drawer
        v-loading="m__loading"
        size="60%"
        :confirm_to_close="false"
        :visible.sync="visible_in_line"
    >
        <template slot="title">
            <div class="flex items-center justify-between flex-1 h-full pr-5">
                <div>
                    <p class="font-semibold text-sm">Chi tiết file từ khóa</p>
                </div>
                <el-button
                    @click="updateKeywordFileFunc()"
                    icon="el-icon-check"
                    type="primary"
                    size="small"
                >
                    {{ $t('button.confirm') }}
                </el-button>
            </div>
        </template>

        <div class="component-box px-5 py-8 m-3">
            <!-- <el-alert
                :title="$t('page.tiktok_identity.info')"
                type="success"
                class="mb-2"
                :closable="false"
            >
            </el-alert> -->

            <!--        <pre>{{ request_list }}</pre>-->
            <span v-if="type==='campaign'">
                    <el-button
                    class="mr-2"
                    :disabled="keyword_selected_list.length===0"
                    type="success"
                    size="small"
                    icon="el-icon-add"
                    @click="setKeywordToContentFunc(keyword_selected_list)"
                    >
                        Thêm từ khóa vào nội dung
                    </el-button>
                </span>
            <el-table class="mt-2" ref="multipleTable" v-loading="m__loading" :data="keyword_data_list" @selection-change="handleSelectionChange">
                <el-table-column
                type="selection"
                width="55">
                </el-table-column>
                <el-table-column label="#" type="index" width="60" />

                <el-table-column width="240" :label="'Từ khóa'">
                    <template slot-scope="scope">
                        {{ scope.row.keyword }}
                    </template>
                </el-table-column>
                <el-table-column width="240" :label="'Độ phổ biến'">
                    <template slot-scope="scope">
                        {{ scope.row.post }}
                    </template>
                </el-table-column>
                <el-table-column width="240" :label="'CTR'">
                    <template slot-scope="scope">
                        {{ scope.row.ctr }}
                    </template>
                </el-table-column>
                <el-table-column width="180" :label="'CVR'">
                    <template slot-scope="scope">
                        {{ scope.row.cvr }}
                    </template>
                </el-table-column>
                <el-table-column width="240" :label="'CPA'">
                    <template slot-scope="scope">
                        {{ scope.row.cpa }}
                    </template>
                </el-table-column>
                <el-table-column width="240" :label="'Chi phí'">
                    <template slot-scope="scope">
                        {{ scope.row.cost }}
                    </template>
                </el-table-column>
                <el-table-column width="240" :label="'Lần hiển thị'">
                    <template slot-scope="scope">
                        {{ scope.row.impression }}
                    </template>
                </el-table-column>
                <el-table-column width="240" :label="'Tỉ lệ xem trong 6 giây'">
                    <template slot-scope="scope">
                        {{ scope.row.play_six_rate }} %
                    </template>
                </el-table-column>
                <el-table-column width="240" :label="'Lượt thích'">
                    <template slot-scope="scope">
                        {{ scope.row.like }}
                    </template>
                </el-table-column>
                <el-table-column width="240" :label="'Lượt chia sẻ'">
                    <template slot-scope="scope">
                        {{ scope.row.share }}
                    </template>
                </el-table-column>
                <el-table-column width="240" :label="'Bình luận'">
                    <template slot-scope="scope">
                        {{ scope.row.comment }}
                    </template>
                </el-table-column>
                <el-table-column
                    fixed="right"
                    min-width="240"
                    :label="$t('common.option')"
                >
                    <template slot-scope="scope">
                        <el-button
                            type="danger"
                            size="small"
                            @click="removeKeyword(scope.row.keyword)"
                        >
                            {{ $t('button.delete') }}
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
    </custom-drawer>
</template>

<script>
import NotifyMe from '@/views/campaign/campaign-wrapper/_components/atosa-create-automatic-rule-drawer/notify-me'
import RunSchedule from '@/views/campaign/campaign-wrapper/_components/atosa-create-automatic-rule-drawer/run-schedule'
import ConditionAndAction from '@/views/campaign/campaign-wrapper/_components/atosa-create-automatic-rule-drawer/condition-and-action'
import { updateAutomatedRule } from '@/services/atosa-tiktok-ads/auto-ads'
import { updateKeywordFile } from '@/services/atosa-tiktok-ads/keyword-tool'

const default_data = {
    switch_notify: true,
    selected_apply_object: {
        dimension: 'CAMPAIGN',
        dimension_ids: [],
        pre_condition_type: 'ALL_ACTIVE_CAMPAIGN'
    },
    rule_name: '',
    rule_exec_info: {
        exec_time_type: 'PER_HALF_HOUR',
        exec_time: '',
        time_period_info: []
    },
    notification: {
        notification_type: 'ANY_CHANGES',
        email_setting: {
            notification_period: 'EVERY_TIME',
            email_exec_time: [],
            no_result_notification: false,
            mute_option: 'UNMUTE'
        }
    },
    selected_actions: [
        {
            subject_type: 'TURN_OFF',
            action_type: 'ADJUST_TO',
            value_type: 'EXACT',
            time_end: '',
            value: {
                use_limit: false,
                value: 0,
                limit: 0
            },
            frequency_info: {
                type: 'ONLY_ONCE',
                custom_frequency_type: 'N_MINTUE_Y_TIMES',
                time: 0,
                count: 0
            }
        }
    ],
    selected_conditions: [
        {
            subject_type: 'spend',
            range_type: 'TODAY',
            calculation_type: 'OF_EACH_OBJECT',
            match_type: 'GT',
            values: [1]
        }
    ]
}

export default {
    components: {},

    props: [
        'visible',
        'tiktok_account_id',
        'advertiser_id',
        'currency',
        'edited_item',
        'keyword_list',
        'keyword_file_selected',
        'type',
        'setKeywordToContent'
    ],

    data() {
        return {
            ...default_data,
            default_pre_condition_type_options: [
                {
                    label: this.$t('common.active'),
                    options: [
                        {
                            value: 'ALL_ACTIVE_CAMPAIGN',
                            value_dimension: 'CAMPAIGN',
                            label: this.$t('common.all_active_campaign')
                        },
                        {
                            value: 'ALL_ACTIVE_AD_GROUP',
                            value_dimension: 'ADGROUP',
                            label: this.$t('common.all_active_adgroup')
                        },
                        {
                            value: 'ALL_ACTIVE_AD',
                            value_dimension: 'AD',
                            label: this.$t('common.all_active_ad')
                        }
                    ]
                },
                {
                    label: this.$t('common.inactive'),
                    options: [
                        {
                            value: 'ALL_INACTIVE_CAMPAIGN',
                            value_dimension: 'CAMPAIGN',
                            label: this.$t('common.all_inactive_campaign')
                        },
                        {
                            value: 'ALL_INACTIVE_AD_GROUP',
                            value_dimension: 'ADGROUP',
                            label: this.$t('common.all_inactive_adgroup')
                        },
                        {
                            value: 'ALL_INACTIVE_AD',
                            value_dimension: 'AD',
                            label: this.$t('common.all_inactive_ad')
                        }
                    ]
                }
            ],
            pre_condition_type_options: [],
            keyword_data_list: [],
            keyword_selected_list: []
        }
    },

    computed: {
        visible_in_line: {
            get: function () {
                return this.visible
            },
            set: function (val) {
                this.$emit('update:visible', val)
            }
        }
    },

    watch: {
        visible_in_line() {},

        keyword_list() {
            this.keyword_data_list = this.keyword_list
        }
    },

    mounted() {
        this.pre_condition_type_options = [
            ...this.default_pre_condition_type_options
        ]
    },

    methods: {
        async removeKeyword(keyword) {
            this.keyword_data_list = this.keyword_data_list.filter(
                (item) => item.keyword !== keyword
            )
        },
        async updateKeywordFileFunc() {
            try {
                await updateKeywordFile({
                    keyword_file_id: this.keyword_file_selected._id,
                    name: this.keyword_file_selected.name,
                    description: this.keyword_file_selected.description,
                    data: JSON.stringify(this.keyword_data_list)
                })

                this.p__showNotify(
                    'success',
                    this.$t('common.request_sent_success')
                )

                this.visible_in_line = false
            } catch (e) {
                console.error(e)
            }
        },
        handleSelectionChange(val) {
            this.keyword_selected_list = val
        },
        setKeywordToContentFunc(keyword_list) {
            this.setKeywordToContent(keyword_list)
        }
    }
}
</script>
