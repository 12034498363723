<template>
    <div
        v-loading.full.screen.lock="m__loading"
        class="w-screen h-screen util-flex-center flex-col"
    >
        <div class="flex items-center space-x-3 mb-6">
            <img
                class="w-[60px] h-[60px]"
                :src="require('@/assets/images/logo_brand.png')"
            />

            <p
                class="uppercase font-semibold text-4xl text-brand-atosa spacing-1"
            >
                ATAME
            </p>
        </div>

        <img
            v-if="status_verify === 1"
            :src="require('@/assets/images/success.gif')"
            class="h-[240px]"
            alt=""
        />
        <img
            v-if="status_verify === 0"
            :src="require('@/assets/images/error.gif')"
            class="h-[240px]"
            alt=""
        />

        <p
            v-if="status_verify === 1"
            class="text-2xl font-semibold mt-4 text-success"
        >
            {{ message_auth_code }}
        </p>

        <p
            v-if="status_verify === 0"
            class="text-2xl font-semibold mt-4 text-red"
        >
            {{ message_auth_code }}
        </p>

        <div class="flex">
            <el-alert
                v-if="error_message"
                :title="error_message"
                type="error"
                :closable="false"
                class="mt-2 mx-auto max-w-[400px]"
            >
            </el-alert>
        </div>

        <p class="text-desc-text mt-4">
            {{ description_message }}
        </p>

        <el-button
            icon="el-icon-back"
            type="primary"
            class="mt-10"
            @click="handleChangeLocation"
        >
            {{ $t('button.back_dashboard') }}
        </el-button>
    </div>
</template>

<script>
import axios from 'axios'
import i18n from '@/plugins/language'

export default {
    data() {
        return {
            error_message: '',
            message_auth_code: '',
            description_message: '',
            status_verify: -1,
            domain: 'https://atame.atosa.asia'
        }
    },

    async mounted() {
        this.m__loading = true

        try {
            const { auth_code, state } = this.$route.query
            if (auth_code && state && state !== 'your_custom_params') {
                const arr = state.split('-----')
                if (arr.length === 3) {
                    if (arr[1] === 'io') {
                        this.domain = 'https://atame.atosa.io'
                    }

                    if (arr[2] === 'en') {
                        i18n.locale = 'en'
                        localStorage.setItem('code_lang', 'en')
                    } else {
                        i18n.locale = 'vi'
                        localStorage.setItem('code_lang', 'vi')
                    }

                    const response_auth = await axios.post(
                        `${process.env.VUE_APP_ATOSA_TIKTOK_ADS_API}/tiktok_accounts`,
                        {
                            auth_code
                        },
                        {
                            headers: {
                                authorization: arr[0]
                            }
                        }
                    )

                    if (response_auth.data.code === 0) {
                        this.p__showNotify(
                            'success',
                            this.$t(
                                'page.connect_tiktok_account.connect_success'
                            )
                        )

                        this.status_verify = 1

                        this.message_auth_code = this.$t(
                            'page.shop.verify_tiktok_business_success'
                        )
                        this.description_message = this.$t(
                            'page.shop.message_verify_success'
                        )

                        try {
                            window.opener.postMessage('close', '*')
                        } catch (error) {
                            console.log(error)
                        }
                    } else {
                        this.error_message = response_auth.data?.message || ''
                        throw Error(response_auth.data?.message || '')
                    }
                }
            }
        } catch (error) {
            this.status_verify = 0
            this.message_auth_code = this.$t(
                'page.shop.verify_tiktok_business_fail'
            )
            this.description_message = this.$t('page.shop.please_verify_again')

            console.error(error)
        }

        this.m__loading = false
    },

    methods: {
        handleChangeLocation() {
            window.location = `${this.domain}${this.p__group_route.ads_manager}/${this.p__route.tiktok_business}/connect?after_verify=1`
        }
    }
}
</script>
