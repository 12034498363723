<template>
    <section v-loading.fullscreen.lock="m__loading">
        <div
            v-if="infor_adgroups.length !== 1 && selected_adgroup"
            class="component-box border-border border-[1px] mb-4 bottom-4 sticky top-0 z-50 w-[560px] overflow-auto"
        >
            <ul class="flex space-x-2">
                <li v-for="(item, index) in infor_adgroups" :key="index">
                    <div class="relative">
                        <div
                            class="w-11 h-11 util-flex-center rounded-lg cursor-pointer border-[1px]"
                            :class="[
                                item.extension.id ===
                                selected_adgroup.extension.id
                                    ? 'text-white bg-brand-atosa--light'
                                    : 'border-border'
                            ]"
                            @click="handleSelectAdgroup(index)"
                        >
                            <p class="text-sm">
                                {{ index + 1 }}
                            </p>
                        </div>
                    </div>
                </li>
            </ul>

            <p class="mt-2 text-desc-text text-sm">
                {{ $t('table.header.ad_group_name') }}:
                {{ selected_adgroup.adgroup_name }}
            </p>
        </div>
        <EditAdForm
            v-if="selected_adgroup && selected_adgroup.extension.infor_ads"
            :items.sync="selected_adgroup.extension.infor_ads"
            :tiktok_account_id="tiktok_account_id"
            :belong_to_atosa="belong_to_atosa"
            :advertiser_id="advertiser_id"
            :objective_type="objective_type"
            :bc_id="bc_id"
            :currency="currency"
            :catalog_id="selected_adgroup.catalog_id"
            :product_source="selected_adgroup.product_source"
            :adgroup_info="selected_adgroup"
            type_form="create"
        ></EditAdForm>

        <div
            class="component-box sticky bottom-0 flex border-bg border-t-[1px] w-[560px]"
        >
            <el-button size="small" class="mr-auto" @click="m__step_inline = 2">
                {{ $t('button.back') }}
            </el-button>
            <el-button
                type="primary"
                size="small"
                icon="el-icon-plus"
                class="ml-auto"
                @click="handleCreateCampaign"
            >
                {{ $t('button.create') }}
            </el-button>
        </div>

        <CreateSuccessDialog
            :visible.sync="show_create_success_dialog"
            :created_campaign_id="created_campaign_id_inline"
            :advertiser_id="advertiser_id"
            :show_warning_add_balance="show_warning_add_balance"
        ></CreateSuccessDialog>
    </section>
</template>

<script>
import createCampaignMixin from '../_mixin'
import { removeEmptyParams } from '@/views/campaign/campaign-wrapper/_utils'
import CreateSuccessDialog from './create-success-dialog'
import EditAdgroupFormMixin from '@/components/orgranisms/edit-adgroup-form/_mixin'
import EditAdFormMixin from '@/components/orgranisms/edit-ad-form/_mixin'
import {
    createFullCampaign,
    createFullAdgroup
} from '@/services/atosa-tiktok-ads/campaign'
import moment from 'moment'

export default {
    components: {
        CreateSuccessDialog
    },

    mixins: [createCampaignMixin, EditAdgroupFormMixin, EditAdFormMixin],

    props: [
        'active_tab',
        'current_balance',
        'infor_adgroups',
        'infor_campaign',
        'belong_to_atosa',
        'objective_type',
        'has_campaign',
        'created_campaign_id',
        'advertiser_id_list',
        'create_with_multi_ads_account'
    ],

    data() {
        return {
            show_create_success_dialog: false,
            show_warning_add_balance: false,
            selected_adgroup: null
        }
    },

    computed: {
        created_campaign_id_inline: {
            get() {
                return this.created_campaign_id
            },
            set(value) {
                this.$emit('update:created_campaign_id', value)
            }
        }
    },

    mounted() {
        console.log('====================================')
        console.log(this.infor_adgroups, 'sadklsdaldjsalkjkl')
        console.log('====================================')
        this.handleSelectAdgroup(0)
    },

    methods: {
        handleSelectAdgroup(index) {
            this.selected_adgroup = this.infor_adgroups[index]

            this.$nextTick(() => {
                this.p__event_bus.$emit('eb_select_first_ad')
            })
        },

        formatCampaign(campaign) {
            if (campaign.objective_type === 'WEB_CONVERSIONS') {
                campaign.objective_type = 'CONVERSIONS'
            }

            if (campaign.objective_type === 'PRODUCT_SALES') {
                campaign.campaign_product_source = 'STORE'
            }

            removeEmptyParams(campaign)
        },

        formatAd(ad, adgroup, objective_type) {
            this.m__formatAd(ad, adgroup, objective_type)
        },

        async handleCreateCampaign() {
            console.log(this.advertiser_id_list, 'advertiser_id_list')
            console.log(
                this.create_with_multi_ads_account,
                'create_with_multi_ads_account'
            )
            if (this.create_with_multi_ads_account) {
                await Promise.all(
                    this.advertiser_id_list.map(async (advertiser_id) => {
                        await this.functionCreateCampaign(advertiser_id)
                    })
                )
            } else {
                await this.functionCreateCampaign(this.advertiser_id)
            }
        },

        async functionCreateCampaign(advertiser_id) {
            let pass = false
            let index_adgroup = 0
            let index_ad = 0
            for (const iterator_adgroup of this.infor_adgroups) {
                index_ad = 0

                for (const iterator_ad of iterator_adgroup.extension
                    .infor_ads) {
                    pass = this.m__validAd(
                        iterator_ad,
                        iterator_adgroup,
                        this.objective_type
                    )
                    if (!pass) {
                        this.handleSelectAdgroup(index_adgroup)
                        this.p__event_bus.$emit('eb_select_ad_item', index_ad)

                        return
                    }
                    index_ad++
                }
                index_adgroup++
            }

            let has_success = false

            this.m__loading = true

            try {
                const adgroups = []

                for (const infor_adgroup of this.infor_adgroups) {
                    let config_list = []

                    if (infor_adgroup.budget_mode === 'BUDGET_MODE_DAY') {
                        config_list = infor_adgroup.extension.config_list
                            .filter((item) => item.type !== 'delete')
                            .map((item) => ({
                                ...item,
                                time: moment(item.time).unix()
                            }))
                    }

                    const data_create_adgroup = {
                        ...infor_adgroup,
                        budget: +infor_adgroup.budget,
                        campaign_type: this.objective_type,
                        belong_to_atosa: false,
                        advertiser_id: advertiser_id,
                        config_list,
                        creative: {
                            creatives: []
                        }
                    }

                    const temp_data_create_adgroup = JSON.parse(
                        JSON.stringify(data_create_adgroup)
                    )

                    removeEmptyParams(temp_data_create_adgroup)

                    this.m__changeActionCategories(temp_data_create_adgroup)

                    this.m__changeFormatScheduleTime(temp_data_create_adgroup)

                    delete temp_data_create_adgroup.extension

                    await Promise.all(
                        infor_adgroup.extension.infor_ads.map(async (ad) => {
                            const image_ids = []

                            if (
                                ((this.objective_type !== 'PRODUCT_SALES' &&
                                    ad.extension.shopping_live_ad_setup !==
                                        'live_video') ||
                                    (this.objective_type === 'PRODUCT_SALES' &&
                                        infor_adgroup.shopping_ads_type ===
                                            'VIDEO' &&
                                        ad.video_id)) &&
                                (ad.extension.video.video_cover_url ||
                                    ad.extension.video.poster_url)
                            ) {
                                const url =
                                    ad.extension.video.video_cover_url ||
                                    ad.extension.video.poster_url

                                const firstPartRegex =
                                    /\/([^\/]+)\/[^\/]+~tplv-noop\.image/
                                const firstPartMatch = url.match(firstPartRegex)
                                const firstPart = firstPartMatch
                                    ? firstPartMatch[1]
                                    : null

                                const secondPartRegex =
                                    /\/([^\/]+)\/([^\/]+)~tplv-noop\.image/
                                const secondPartMatch =
                                    url.match(secondPartRegex)
                                const secondPart = secondPartMatch
                                    ? secondPartMatch[2]
                                    : null

                                image_ids.push(`${firstPart}/${secondPart}`)
                            }

                            const creative = {
                                ...ad,
                                image_ids,
                                identity_authorized_bc_id:
                                    ad.identity_authorized_bc_id,
                                identity_id: ad.identity_id,
                                identity_type: ad.identity_type
                            }

                            this.formatAd(
                                creative,
                                infor_adgroup,
                                this.objective_type
                            )

                            const item = this.m__returnAd(
                                creative,
                                infor_adgroup,
                                this.objective_type
                            )

                            delete item.extension

                            temp_data_create_adgroup.creative.creatives.push(
                                item
                            )
                        })
                    )

                    adgroups.push(temp_data_create_adgroup)
                }

                if (this.created_campaign_id_inline) {
                    let number_create_error_adgroup = 0

                    await Promise.all(
                        adgroups.map(async (infor_adgroup) => {
                            const response_create_adgroup =
                                await createFullAdgroup(
                                    this.tiktok_account_id,
                                    {
                                        ...infor_adgroup,
                                        campaign_id:
                                            this.created_campaign_id_inline
                                    }
                                )

                            if (
                                response_create_adgroup.code !== 0 ||
                                !response_create_adgroup.data?.adgroup_id
                            ) {
                                number_create_error_adgroup++
                                this.p__showNotify(
                                    'error',
                                    this.$t('message.xxx_fail', {
                                        xxx: `${this.$t(
                                            `button.create`
                                        )} ${this.$t(`common.campaign`)}`
                                    })
                                )
                            }
                        })
                    )

                    has_success = number_create_error_adgroup < adgroups.length
                } else {
                    const data_create_camp = {
                        ...this.infor_campaign,
                        budget: +this.infor_campaign.budget,
                        belong_to_atosa: this.belong_to_atosa,
                        advertiser_id: advertiser_id,
                        adgroups
                    }

                    this.formatCampaign(data_create_camp)

                    const response_create_campaign = await createFullCampaign(
                        this.tiktok_account_id,
                        data_create_camp
                    )

                    if (response_create_campaign.code === 0) {
                        has_success = true
                    }
                }
            } catch (error) {
                console.error(error)
                this.p__showNotify(
                    'error',
                    this.$t('message.xxx_fail', {
                        xxx: `${this.$t(`button.create`)} ${this.$t(
                            `common.campaign`
                        )}`
                    })
                )
            }

            this.m__loading = false

            if (has_success) {
                this.p__showNotify(
                    'success',
                    this.$t('message.xxx_success', {
                        xxx: `${this.$t(`button.create`)} ${this.$t(
                            `common.campaign`
                        )}`
                    })
                )

                setTimeout(() => {
                    if (this.infor_campaign?.budget) {
                        this.show_warning_add_balance =
                            +this.infor_campaign.budget * 3 >
                            this.current_balance
                    }
                    this.show_create_success_dialog = true
                }, 112)
            }
        }

        // async handleCreateCampaign() {
        //     let pass = false
        //     let index_adgroup = 0
        //     let index_ad = 0
        //     for (const iterator_adgroup of this.infor_adgroups) {
        //         index_ad = 0

        //         for (const iterator_ad of iterator_adgroup.extension
        //             .infor_ads) {
        //             pass = this.m__validAd(
        //                 iterator_ad,
        //                 iterator_adgroup,
        //                 this.objective_type
        //             )
        //             if (!pass) {
        //                 this.handleSelectAdgroup(index_adgroup)
        //                 this.p__event_bus.$emit('eb_select_ad_item', index_ad)

        //                 return
        //             }
        //             index_ad++
        //         }
        //         index_adgroup++
        //     }

        //     let has_success = false

        //     this.m__loading = true

        //     try {
        //         const adgroups = []

        //         for (const infor_adgroup of this.infor_adgroups) {
        //             let config_list = []

        //             if (infor_adgroup.budget_mode === 'BUDGET_MODE_DAY') {
        //                 config_list = infor_adgroup.extension.config_list
        //                     .filter((item) => item.type !== 'delete')
        //                     .map((item) => ({
        //                         ...item,
        //                         time: moment(item.time).unix()
        //                     }))
        //             }

        //             const data_create_adgroup = {
        //                 ...infor_adgroup,
        //                 budget: +infor_adgroup.budget,
        //                 campaign_type: this.objective_type,
        //                 belong_to_atosa: this.belong_to_atosa,
        //                 advertiser_id: this.advertiser_id,
        //                 config_list,
        //                 creative: {
        //                     creatives: []
        //                 }
        //             }

        //             const temp_data_create_adgroup = JSON.parse(
        //                 JSON.stringify(data_create_adgroup)
        //             )

        //             removeEmptyParams(temp_data_create_adgroup)

        //             this.m__changeActionCategories(temp_data_create_adgroup)

        //             this.m__changeFormatScheduleTime(temp_data_create_adgroup)

        //             delete temp_data_create_adgroup.extension

        //             await Promise.all(
        //                 infor_adgroup.extension.infor_ads.map(async (ad) => {
        //                     const image_ids = []

        //                     if (
        //                         ((this.objective_type !== 'PRODUCT_SALES' &&
        //                             ad.extension.shopping_live_ad_setup !==
        //                                 'live_video') ||
        //                             (this.objective_type === 'PRODUCT_SALES' &&
        //                                 infor_adgroup.shopping_ads_type ===
        //                                     'VIDEO' &&
        //                                 ad.video_id)) &&
        //                         (ad.extension.video.video_cover_url ||
        //                             ad.extension.video.poster_url)
        //                     ) {
        //                         const url =
        //                             ad.extension.video.video_cover_url ||
        //                             ad.extension.video.poster_url

        //                         const firstPartRegex =
        //                             /\/([^\/]+)\/[^\/]+~tplv-noop\.image/
        //                         const firstPartMatch = url.match(firstPartRegex)
        //                         const firstPart = firstPartMatch
        //                             ? firstPartMatch[1]
        //                             : null

        //                         const secondPartRegex =
        //                             /\/([^\/]+)\/([^\/]+)~tplv-noop\.image/
        //                         const secondPartMatch =
        //                             url.match(secondPartRegex)
        //                         const secondPart = secondPartMatch
        //                             ? secondPartMatch[2]
        //                             : null

        //                         image_ids.push(`${firstPart}/${secondPart}`)
        //                     }

        //                     const creative = {
        //                         ...ad,
        //                         image_ids
        //                     }

        //                     this.formatAd(
        //                         creative,
        //                         infor_adgroup,
        //                         this.objective_type
        //                     )

        //                     const item = this.m__returnAd(
        //                         creative,
        //                         infor_adgroup,
        //                         this.objective_type
        //                     )

        //                     delete item.extension

        //                     temp_data_create_adgroup.creative.creatives.push(
        //                         item
        //                     )
        //                 })
        //             )

        //             adgroups.push(temp_data_create_adgroup)
        //         }

        //         if (this.created_campaign_id_inline) {
        //             let number_create_error_adgroup = 0

        //             await Promise.all(
        //                 adgroups.map(async (infor_adgroup) => {
        //                     const response_create_adgroup =
        //                         await createFullAdgroup(
        //                             this.tiktok_account_id,
        //                             {
        //                                 ...infor_adgroup,
        //                                 campaign_id:
        //                                     this.created_campaign_id_inline
        //                             }
        //                         )

        //                     if (
        //                         response_create_adgroup.code !== 0 ||
        //                         !response_create_adgroup.data?.adgroup_id
        //                     ) {
        //                         number_create_error_adgroup++
        //                         this.p__showNotify(
        //                             'error',
        //                             this.$t('message.xxx_fail', {
        //                                 xxx: `${this.$t(
        //                                     `button.create`
        //                                 )} ${this.$t(`common.campaign`)}`
        //                             })
        //                         )
        //                     }
        //                 })
        //             )

        //             has_success = number_create_error_adgroup < adgroups.length
        //         } else {
        //             const data_create_camp = {
        //                 ...this.infor_campaign,
        //                 budget: +this.infor_campaign.budget,
        //                 belong_to_atosa: this.belong_to_atosa,
        //                 advertiser_id: this.advertiser_id,
        //                 adgroups
        //             }

        //             this.formatCampaign(data_create_camp)

        //             const response_create_campaign = await createFullCampaign(
        //                 this.tiktok_account_id,
        //                 data_create_camp
        //             )

        //             if (response_create_campaign.code === 0) {
        //                 has_success = true
        //             }
        //         }
        //     } catch (error) {
        //         console.error(error)
        //         this.p__showNotify(
        //             'error',
        //             this.$t('message.xxx_fail', {
        //                 xxx: `${this.$t(`button.create`)} ${this.$t(
        //                     `common.campaign`
        //                 )}`
        //             })
        //         )
        //     }

        //     this.m__loading = false

        //     if (has_success) {
        //         this.p__showNotify(
        //             'success',
        //             this.$t('message.xxx_success', {
        //                 xxx: `${this.$t(`button.create`)} ${this.$t(
        //                     `common.campaign`
        //                 )}`
        //             })
        //         )

        //         setTimeout(() => {
        //             if (this.infor_campaign?.budget) {
        //                 this.show_warning_add_balance =
        //                     +this.infor_campaign.budget * 3 >
        //                     this.current_balance
        //             }
        //             this.show_create_success_dialog = true
        //         }, 112)
        //     }
        // }
    }
}
</script>
