<template>
    <custom-drawer
        v-loading="m__loading"
        size="60%"
        :confirm_to_close="false"
        :visible.sync="visible_in_line"
    >
        <template slot="title">
            <div class="flex items-center justify-between flex-1 h-full pr-5">
                <div>
                    <p class="font-semibold text-sm">Danh sách file đã lưu</p>
                </div>
                <!-- <el-button
                    icon="el-icon-check"
                    type="primary"
                    size="small"
                    @click="handleEditAutomatedRule"
                >
                    {{ $t('button.confirm') }}
                </el-button> -->
            </div>
        </template>

        <div class="component-box px-5 py-8 m-3">
            <!-- <el-alert
                :title="$t('page.tiktok_identity.info')"
                type="success"
                class="mb-2"
                :closable="false"
            >
            </el-alert> -->

            <!--        <pre>{{ request_list }}</pre>-->
            <el-table v-loading="m__loading" :data="file_list">
                <el-table-column label="#" type="index" width="60" />

                <el-table-column width="120" :label="'ID'">
                    <template slot-scope="scope">
                        {{ scope.row._id }}
                    </template>
                </el-table-column>
                <el-table-column width="130" :label="$t('common.name')">
                    <template slot-scope="scope">
                        {{ scope.row.name }}
                    </template>
                </el-table-column>
                <el-table-column width="240" :label="$t('common.description')">
                    <template slot-scope="scope">
                        {{ scope.row.description }}
                    </template>
                </el-table-column>
                <el-table-column width="240" :label="$t('common.created_at')">
                    <template slot-scope="scope">
                        {{
                            scope.row.created_at
                                | f__format_moment($t('common.format_date'))
                        }}
                    </template>
                </el-table-column>
                <el-table-column
                    fixed="right"
                    min-width="240"
                    :label="$t('common.option')"
                >
                    <template slot-scope="scope">
                        <el-button
                            type="primary"
                            size="small"
                            @click="gotoDetailKeywordFile(scope.row)"
                        >
                            Chi tiết
                        </el-button>

                        <el-button
                            type="danger"
                            size="small"
                            @click="deleteKeywordFile(scope.row._id)"
                        >
                            Xóa
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <KeywordFileDetailDrawer
            :visible.sync="is_keyword_file_detail_drawer_displayed"
            :keyword_list="file_detail_keyword_list"
            :keyword_file_selected="selected_keyword_file"
            :type="type"
            :setKeywordToContent="connectFunc"
        ></KeywordFileDetailDrawer>
    </custom-drawer>
</template>

<script>
import NotifyMe from '@/views/campaign/campaign-wrapper/_components/atosa-create-automatic-rule-drawer/notify-me'
import RunSchedule from '@/views/campaign/campaign-wrapper/_components/atosa-create-automatic-rule-drawer/run-schedule'
import ConditionAndAction from '@/views/campaign/campaign-wrapper/_components/atosa-create-automatic-rule-drawer/condition-and-action'
import { updateAutomatedRule } from '@/services/atosa-tiktok-ads/auto-ads'
import KeywordFileDetailDrawer from '@/views/keyword-tool/_components/keyword-file-detail-drawer'
import {
    getKeywordFile,
    deleteKeywordReport
} from '@/services/atosa-tiktok-ads/keyword-tool'

export default {
    components: {
        KeywordFileDetailDrawer
    },

    props: [
        'visible',
        'tiktok_account_id',
        'advertiser_id',
        'currency',
        'edited_item',
        'keyword_file_list',
        'type',
        'setKeywordToContent'
    ],

    data() {
        return {
            default_pre_condition_type_options: [
                {
                    label: this.$t('common.active'),
                    options: [
                        {
                            value: 'ALL_ACTIVE_CAMPAIGN',
                            value_dimension: 'CAMPAIGN',
                            label: this.$t('common.all_active_campaign')
                        },
                        {
                            value: 'ALL_ACTIVE_AD_GROUP',
                            value_dimension: 'ADGROUP',
                            label: this.$t('common.all_active_adgroup')
                        },
                        {
                            value: 'ALL_ACTIVE_AD',
                            value_dimension: 'AD',
                            label: this.$t('common.all_active_ad')
                        }
                    ]
                },
                {
                    label: this.$t('common.inactive'),
                    options: [
                        {
                            value: 'ALL_INACTIVE_CAMPAIGN',
                            value_dimension: 'CAMPAIGN',
                            label: this.$t('common.all_inactive_campaign')
                        },
                        {
                            value: 'ALL_INACTIVE_AD_GROUP',
                            value_dimension: 'ADGROUP',
                            label: this.$t('common.all_inactive_adgroup')
                        },
                        {
                            value: 'ALL_INACTIVE_AD',
                            value_dimension: 'AD',
                            label: this.$t('common.all_inactive_ad')
                        }
                    ]
                }
            ],
            pre_condition_type_options: [],
            file_list: [],
            is_keyword_file_detail_drawer_displayed: false,
            selected_keyword_file: {},
            file_detail_keyword_list: []
        }
    },

    computed: {
        visible_in_line: {
            get: function () {
                return this.visible
            },
            set: function (val) {
                this.$emit('update:visible', val)
            }
        }
    },

    watch: {
        visible_in_line() {
            this.getDataKeywordFile()
        },

        switch_notify() {
            if (this.switch_notify) {
                if (
                    this.selected_actions.some(
                        (item) => item.subject_type === 'MESSAGE'
                    )
                ) {
                    this.notification.notification_type = 'TASK_FINISH'
                } else {
                    this.notification.notification_type = 'ANY_CHANGES'
                }
            } else {
                this.notification.notification_type = 'NOT_NOTIFICATION'
            }
        },

        m__selected_tiktok_business: function () {
            this.getDataKeywordFile()
        }
    },

    mounted() {
        this.getDataKeywordFile()

        this.pre_condition_type_options = [
            ...this.default_pre_condition_type_options
        ]
    },

    methods: {
        checkOverlap(arr) {
            for (let i = 0; i < arr.length; i++) {
                const subArr1 = arr[i]
                const [start1, end1] = subArr1

                for (let j = i + 1; j < arr.length; j++) {
                    const subArr2 = arr[j]
                    const [start2, end2] = subArr2

                    if (
                        (start1 <= start2 && start2 <= end1) ||
                        (start2 <= start1 && start1 <= end2)
                    ) {
                        return true // Giao nhau
                    }
                }
            }
            return false // Không giao nhau
        },

        async handleEditAutomatedRule() {
            if (!this.rule_name) {
                this.p__showNotify(
                    'warning',
                    this.$t('page.campaign.please_select_input_rule_name')
                )

                document
                    .getElementById('input_rule_name_wrapper')
                    .scrollIntoView({ behavior: 'smooth' })
                document.getElementById('input_rule_name').focus()
                document.getElementById('input_rule_name').className +=
                    ' border-red border-[1px] rounded-[8px]'

                return
            }

            if (
                this.rule_exec_info.exec_time_type ===
                'HALF_HOUR_IN_SPECIFIC_TIME_PERIOD'
            ) {
                let has_error = false
                const specified_times = []

                this.rule_exec_info.time_period_info.forEach((info) => {
                    if (info.start_time && info.end_time) {
                        let arr = info.start_time.split(':')
                        const start_time_value = +arr[0] * 60 + +arr[1]

                        arr = info.end_time.split(':')
                        const end_time_value = +arr[0] * 60 + +arr[1]
                        if (start_time_value > end_time_value) {
                            has_error = true
                            this.p__showNotify(
                                'warning',
                                this.$t(
                                    'page.campaign.message_start_time_less_than_or_equal_end_time_in_specified_time_period_automated_rule'
                                )
                            )

                            return
                        }

                        if (!info.num.length) {
                            has_error = true
                            this.p__showNotify(
                                'warning',
                                this.$t(
                                    'page.campaign.message_havent_select_day_in_specified_time_period_automated_rule'
                                )
                            )

                            return
                        }

                        info.num.forEach((day) => {
                            const second = +day * 24 * 60
                            specified_times.push([
                                second + start_time_value,
                                second + end_time_value
                            ])
                        })
                    } else {
                        has_error = true
                        return
                    }
                })

                if (this.checkOverlap(specified_times)) {
                    has_error = true
                    this.p__showNotify(
                        'warning',
                        this.$t(
                            'page.campaign.message_overlap_specified_time_period_automated_rule'
                        )
                    )
                }

                if (has_error) {
                    return
                }
            }

            this.m__loading = true

            try {
                const conditions = this.selected_conditions.map((item) => {
                    if (!item.calculation_type) {
                        delete item.calculation_type
                    }

                    return {
                        ...item,
                        values: item.values.map((i) => `${i}`)
                    }
                })

                await updateAutomatedRule(this.tiktok_account_id, {
                    advertiser_id: this.advertiser_id,
                    rule_id: this.edited_item._id,
                    rule: {
                        name: this.rule_name,
                        notification: this.notification,
                        rule_exec_info: this.rule_exec_info,
                        apply_objects: [this.selected_apply_object],
                        actions: [...this.selected_actions],
                        conditions
                    }
                })

                this.p__showNotify(
                    'success',
                    this.$t('common.create_data_success')
                )
                this.p__event_bus.$emit('eb_reload_list_automated_rules')

                this.visible_in_line = false
            } catch (error) {
                console.error(error)
            }

            this.m__loading = false
        },

        async deleteKeywordFile(keyword_file_id) {
            try {
                await this.$confirm(
                    this.$t('common.delete_data_confirm'),
                    this.$t('common.warning'),
                    {
                        confirmButtonText: this.$t('button.confirm'),
                        cancelButtonText: this.$t('button.close'),
                        type: 'warning'
                    }
                )
            } catch (e) {
                return
            }

            await deleteKeywordReport(keyword_file_id)

            this.getDataKeywordFile()

            try {
                this.p__showNotify(
                    'success',
                    this.$t('common.delete_data_success')
                )
            } catch (e) {
                console.error(e)
            }
        },
        async gotoDetailKeywordFile(keywordFile) {
            this.is_keyword_file_detail_drawer_displayed = true
            this.selected_keyword_file = keywordFile
            console.log(keywordFile, 'keywordFile')

            this.file_detail_keyword_list = JSON.parse(keywordFile.data)
        },
        async getDataKeywordFile() {
            this.m__loading = true

            try {
                const response = await getKeywordFile()
                this.file_list = response.data

                console.log(response.data, this.file_list)
            } catch (error) {
                console.error(error)
            }

            this.m__loading = false
        },
        connectFunc (keyword) {
            this.setKeywordToContent(keyword)
            this.visible_in_line = false
        }
    }
}
</script>
