<template>
  <div id='container' style='width: 100%; height: 400px'></div>
</template>

<script>
import Highcharts from 'highcharts'

export default {
  name: 'TrafficChart',
  props: {
    trafficData: {
      type: Array,
      required: true
    }
  },
  mounted() {
    this.renderChart()
  },
  methods: {
    renderChart() {
      const categories = this.trafficData.map(data => data.time)
      const mobileImpressions = this.trafficData.map(data => data.mobileImpression)
      const pcImpressions = this.trafficData.map(data => data.pcImpression)
      const totalImpressions = this.trafficData.map(data => data.totalImpression)

      Highcharts.chart('container', {
        chart: {
          zoomType: 'xy'
        },
        title: {
          text: 'Traffic Impressions'
        },
        xAxis: {
          categories: categories,
          crosshair: true
        },
        yAxis: [
          {
            title: {
              text: 'Impressions'
            }
          },
          {
            title: {
              text: 'Total Impressions'
            },
            opposite: true
          }
        ],
        tooltip: {
          shared: true
        },
        legend: {
          layout: 'vertical',
          align: 'left',
          verticalAlign: 'top',
          floating: true,
          backgroundColor: Highcharts.defaultOptions.chart.backgroundColor || 'white'
        },
        series: [
          {
            name: 'Mobile Impressions',
            type: 'column',
            data: mobileImpressions,
            tooltip: {
              valueSuffix: ''
            },
            color: '#7cb5ec'
          },
          {
            name: 'PC Impressions',
            type: 'column',
            data: pcImpressions,
            tooltip: {
              valueSuffix: ''
            },
            color: '#434348'
          },
          {
            name: 'Total Impressions',
            type: 'line',
            yAxis: 1,
            data: totalImpressions,
            tooltip: {
              valueSuffix: ''
            },
            color: '#90ed7d'
          }
        ]
      })
    }
  }
}
</script>

<style scoped>
#container {
  width: 100%;
  height: 400px
}
</style>
